import React, { useState, useEffect, useMemo } from 'react';
import { FormBox } from "../doctor/style";
import { Grid, Typography, Stack, FormControlLabel, Checkbox } from '@mui/material';
import { Button } from "src/components/shared";
import Select from 'src/components/shared/Form/Select';
import Datepicker from 'src/components/shared/Form/Datepicker';
import InputComponent from 'src/components/shared/Form/Input';
import AppTimePicker from 'src/components/shared/Form/Timepicker';
import { fetchDoctornameRequest, fetchPatientnameRequest, fetchTokenAppointmentByPatientIdRequest, addTreatmentRequest, fetchClinicnameRequest, fetchTreatmentByIdRequest, updateTreatmentRequest } from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import toast from "src/utils/toast";
import { useNavigate, useParams } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  total: Yup.number().required('Total is required').positive('Total must be positive').integer('Total must be an integer'),
  patient: Yup.string().required('Patient is required'),
  user: Yup.string().required('User is required'),
  clinic: Yup.string().required('Clinic is required'),
  tokenAppointment: Yup.string().required('Please select the token appointment!'),
  treatmentType: Yup.string().required('Treatment Type is required'),
  days: Yup.array().when('treatmentType', {
    is: 'visiting',
    then: schema => schema.min(1, 'At least one day must be selected').required('Days are required for visiting treatment'),
    otherwise: schema => schema.nullable(),
  }),
  time: Yup.string().when('treatmentType', {
    is: 'visiting',
    then: schema => schema.required('Time is required for visiting treatment'),
    otherwise: schema => schema.nullable(),
  }),
  dateTimes: Yup.array().when('treatmentType', {
    is: 'sitting',
    then: schema => schema.of(
      Yup.object().shape({
        date: Yup.date().required('Date is required'),
        time: Yup.string().required('Time is required'),
      })
    ).required('Date and Time are required for sitting treatment'),
    otherwise: schema => schema.nullable(),
  }),
});


function Treatment() {
  const [sittings, setSittings] = useState(0);
  const [dateTimes, setDateTimes] = useState([]);
  const { id } = useParams();
  const [treatmentType, setTreatmentType] = useState('visiting');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [tokenAppointments, setTokenAppointments] = useState([]);
  const dispatch = useDispatch();
  const doctors = useSelector((state) => state.app.doctors);
  const clinics = useSelector((state) => state.app.clinics || []);
  const patients = useSelector((state) => state.app.patients);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    name: '',
    total: '',
    treatmentType: '',
    days: '',
    user: '',
    patient: '',
    tokenAppointment: '',
    clinic: '',
    time: '',
    dateTimes: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(fetchDoctornameRequest()),
          dispatch(fetchClinicnameRequest()),
          dispatch(fetchPatientnameRequest()),
        ]);
  
        if (id) {
          const treatment = await new Promise((resolve, reject) =>
            dispatch(fetchTreatmentByIdRequest(id, resolve, reject))
          );
  
          handleFetchTokenAppointments(treatment.patient);
  
          setInitialValues({
            name: treatment.name || '',
            total: treatment.total || '',
            treatmentType: treatment.treatmentType || '',
            days: treatment.days || [],
            user: treatment.user || '',
            patient: treatment.patient || '',
            tokenAppointment: treatment.tokenAppointment || '',
            clinic: treatment.clinic || '',
            time: treatment.time || '',
            dateTimes: treatment.dateTimes || [],
          });
        }
  
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [dispatch, id]);
  

  const handleFetchTokenAppointments = (patientId) => {
    dispatch(fetchTokenAppointmentByPatientIdRequest(patientId, (data) => {
      setTokenAppointments(data);
    }, (error) => {
      console.error('Error:', error);
    }));
  };

  const handlePatientChange = (e) => {
    const patientId = e.target.value;
    setSelectedPatient(patientId);
    handleFetchTokenAppointments(patientId);
  };

  const handleSittingsChange = (event, setFieldValue) => {
    const value = parseInt(event.target.value, 10);
    setSittings(value);
    setDateTimes(Array(value).fill({ date: null, time: null }));
    setFieldValue('total', value);
    setFieldValue('dateTimes', Array(value).fill({ date: null, time: null }));
  };

  const handleDateChange = (index, date, setFieldValue) => {
    const newDateTimes = [...dateTimes];
    newDateTimes[index].date = date;
    setDateTimes(newDateTimes);
    setFieldValue('dateTimes', newDateTimes);
  };

  const handleTimeChange = (index, time, setFieldValue) => {
    const newDateTimes = [...dateTimes];
    newDateTimes[index].time = time;
    setDateTimes(newDateTimes);
    setFieldValue('dateTimes', newDateTimes);
  };

  const doctorOptions = useMemo(() => (
    doctors.map((doctor) => ({
      label: doctor.name,
      value: doctor.id,
    }))
  ), [doctors]);

  const patientOptions = useMemo(() => (
    patients.map((patient) => ({
      label: patient.name,
      value: patient.id,
    }))
  ), [patients]);

  const clinicOptions = useMemo(() => (
    clinics.map((clinic) => ({
      label: clinic.name,
      value: clinic.id,
    }))
  ), [clinics]);

  const tokenAppointmentOptions = useMemo(() => (
    tokenAppointments.map((appointment) => ({
      label: appointment.id, 
      value: appointment.id,
    }))
  ), [tokenAppointments]);
  
  useEffect(() => {
    const tokenAppointmentValue = initialValues.tokenAppointment;
    const isValidTokenAppointment = tokenAppointmentOptions.some(option => option.value === tokenAppointmentValue);
  
    if (!isValidTokenAppointment) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        tokenAppointment: '', 
      }));
    }
  }, [tokenAppointmentOptions, initialValues.tokenAppointment]);
  

  const handleSubmit = async (values, { resetForm }) => {
    const treatment = { ...values };

    try {
      if (id) {
        await new Promise((resolve, reject) => dispatch(updateTreatmentRequest(id, treatment, resolve, reject)));
        toast.success('Treatment Updated Successfully');
      } else {
        await new Promise((resolve, reject) => dispatch(addTreatmentRequest(treatment, resolve, reject)));
        toast.success('Treatment Added Successfully');
        resetForm();
      }
      navigate('/treatments');
    } catch (error) {
      toast.error('Error saving treatment');
    }
  };

  useEffect(() => {
    console.log(initialValues);
  }, [initialValues]);

  return (
    <FormBox marginTop='20px'>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Typography variant="h4" color='#393A96' fontWeight='bold' sx={{ marginLeft: '2%' }}>
          Treatment
        </Typography>

        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, setFieldValue, touched, errors, handleSubmit, isValid }) => (
            <Form onSubmit={handleSubmit}>
              <Grid item>
                <Typography variant='h5' fontWeight='bold'>
                  User
                </Typography>
                <Select
                  variant='outlined'
                  fullWidth
                  name='user'
                  value={values.user}
                  onChange={(e) => setFieldValue('user', e.target.value)}
                  options={doctorOptions}
                  placeholder="Select a doctor"
                  error={touched.user && Boolean(errors.user)}
                  helperText={touched.user && errors.user}
                />
              </Grid>
              <Grid item>
                <Typography variant='h5' fontWeight='bold'>
                  Clinic
                </Typography>
                <Select
                  variant='outlined'
                  fullWidth
                  name='clinic'
                  value={values.clinic}
                  onChange={(e) => setFieldValue('clinic', e.target.value)}
                  options={clinicOptions}
                  placeholder="Select a clinic"
                  error={touched.clinic && Boolean(errors.clinic)}
                  helperText={touched.clinic && errors.clinic}
                />
              </Grid>
              <Grid item>
                <Typography variant='h5' fontWeight='bold'>
                  Patient
                </Typography>
                <Select
                  variant='outlined'
                  fullWidth
                  name='patient'
                  value={values.patient}
                  onChange={(e) => {
                    handlePatientChange(e);
                    setFieldValue('patient', e.target.value);
                  }}
                  options={patientOptions}
                  placeholder="Select a patient"
                  error={touched.patient && Boolean(errors.patient)}
                  helperText={touched.patient && errors.patient}
                />
              </Grid>
              <Grid item>
                <Typography variant='h5' fontWeight='bold'>
                  Appointment
                </Typography>
                <Select
                  variant='outlined'
                  fullWidth
                  name='tokenAppointment'
                  value={values.tokenAppointment}
                  onChange={handleChange}
                  options={tokenAppointmentOptions}
                  placeholder="Select a token appointment"
                  error={touched.tokenAppointment && Boolean(errors.tokenAppointment)}
                  helperText={touched.tokenAppointment && errors.tokenAppointment}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Name
                </Typography>
                <InputComponent
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Treatment Type
                </Typography>
                <Select
                  fullWidth
                  name="treatmentType"
                  value={values.treatmentType}
                  options={[
                    { label: 'Sitting', value: 'sitting' },
                    { label: 'Visit', value: 'visiting' },
                  ]}
                  onChange={(e) => {
                    handleChange(e);
                    setTreatmentType(e.target.value);
                    setFieldValue('treatmentType', e.target.value);
                  }}
                  variant="outlined"
                />
              </Grid>

              {values.treatmentType === 'visiting' && (
                <>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Total Visit
                    </Typography>
                    <InputComponent
                      variant="outlined"
                      fullWidth
                      name="total"
                      value={values.total}
                      onChange={(e) => {
                        handleChange(e);
                        handleSittingsChange(e, setFieldValue);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold" sx={{ marginTop: 2 }}>
                      When Visit
                    </Typography>
                    <Grid item container spacing={1} sx={{ position: 'relative', bottom: '30px', left: '12%' }}>
                      {[
                        { label: 'M', value: 'mon' },
                        { label: 'T', value: 'tue' },
                        { label: 'W', value: 'wed' },
                        { label: 'T', value: 'thu' },
                        { label: 'F', value: 'fri' },
                        { label: 'S', value: 'sat' },
                        { label: 'S', value: 'sun' },
                      ].map((day, index) => (
                        <Grid item key={index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.days.includes(day.value)}
                                onChange={() => {
                                  const updatedDays = values.days.includes(day.value)
                                    ? values.days.filter(d => d !== day.value)
                                    : [...values.days, day.value];
                                  setFieldValue('days', updatedDays);
                                }}
                              />
                            }
                            label={day.label}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid item sx={{ position: 'relative', bottom: 50 }}>
                    <Typography variant="h5" fontWeight="bold" sx={{ marginTop: 2 }}>
                      Visit Time
                    </Typography>
                    <AppTimePicker
                      fullWidth
                      name="time"
                      value={values.time}
                      onChange={(time) => setFieldValue('time', time)}
                    />
                  </Grid>
                </>
              )}
              

              {values.treatmentType === 'sitting' && (
                <>
                   <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Total Sitting
                    </Typography>
                    <InputComponent
                      variant="outlined"
                      fullWidth
                      name="total"
                      value={values.total}
                      onChange={(e) => {
                        handleChange(e);
                        handleSittingsChange(e, setFieldValue);
                      }}
                      error={touched.total && Boolean(errors.total)}
                      helperText={touched.total && errors.total}
                    />
                    </Grid>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Select Date
                    </Typography>
                    <Datepicker
                      fullWidth
                      value={dateTimes[0]?.date || null}
                      onChange={(date) => {
                        setDateTimes([{ ...dateTimes[0], date }]);
                        setFieldValue('dateTimes', [{ ...dateTimes[0], date }]);
                      }}
                    />
                    </Grid>
                    <Grid item>
                    <Typography variant="h5" fontWeight="bold" sx={{ marginTop: 2 }}>
                      Select Time
                    </Typography>
                    <AppTimePicker
                      fullWidth
                      value={dateTimes[0]?.time || null}
                      onChange={(time) => {
                        setDateTimes([{ ...dateTimes[0], time }]);
                        setFieldValue('dateTimes', [{ ...dateTimes[0], time }]);
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item container justifyContent="center" sx={{ marginTop: '30px' }}>
                <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                  Save Treatment
                </Button>
              </Grid>

            </Form>
          )}
        </Formik>
      </Grid>
    </FormBox>
  );
}

export default Treatment;
