import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Stack,
  Popover,
  IconButton,
  Grid,
  Box,
  Drawer,
  Typography,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AppGrid from 'src/components/App/AppGrid';
import PickerWithButtonField from 'src/components/App/DatePicker';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPatientsRequest, deletePatientRequest } from 'src/modules/app/store/appActions';
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';

function PatientsContainer() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState();
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [page, setPage] = useState(1);  
const [perPage, setPerPage] = useState(10);
const [totalPages, setTotalPages] = useState(0);
  const [patients, setPatients] = useState([]);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const clinic = useSelector((state) => state.app.clinic);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN');
      const response = await new Promise((resolve, reject) =>
        dispatch(fetchPatientsRequest(token, page, perPage, resolve, reject)),
      );
      if (response && response.data) {
        setPatients(response.data);
        setTotalPages(response.meta.totalPages); 
      }
    };
  
    fetchData();
  }, [dispatch, page, perPage]);
  
  

  const handleActionMenu = (event, id) => {
    console.log('Opening action menu for patient ID:', id);
    setOpen(event.currentTarget);
    setSelectedPatientId(id);
  };

  const handleDeletePatient = async () => {
    const response = await new Promise((resolve, reject) =>
      dispatch(deletePatientRequest(selectedPatientId, resolve, reject)),
    );
    if (response) {
      setPatients(patients.filter((patient) => patient.id !== selectedPatientId));
      handleCloseMenu();
      toast.success('Patient Deleted Successfully');
      // console.log('Patient deleted successfully');
    } else {
      console.log('Failed to delete patient');
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const AddPatient = (event) => {
    navigate('/patient')
  };

  const handleViewDetails = (dataIndex) => () => {
    setSelectedPatient(patients[dataIndex]);
    setDrawerOpen(true);
    handleCloseMenu();
  };

  const handleAttendNow = () => {
    handleCloseMenu();
    // navigate(`/${clinic.clinicId}/${clinic.doctorId}`);
  };

  const handleEditPatient = () => {
    if (selectedPatientId) {
      navigate(`/patient/${selectedPatientId}`);
    } else {
      toast.error('Patient information is missing');
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedPatient(null);
  };

  const columns = [
    { label: 'Name', name: 'fullName' },
    { label: 'Gender', name: 'gender' },
    { label: 'Age', name: 'age' },
    { label: 'Area', name: 'area' },
    {
      label: '',
      name: '',
      options: {
        customBodyRenderLite: (dataIndex) => (
          <>
            <IconButton onClick={(event) => handleActionMenu(event, patients[dataIndex]?.id)}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{ sx: { width: 170 } }}
            >
              <MenuItem onClick={handleViewDetails(dataIndex)}>
                <Stack spacing={1} direction="row">
                  <VisibilityIcon />
                  <span>View Details</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleAttendNow}>
                <Stack spacing={1} direction="row">
                  <CheckCircleIcon />
                  <span>Attend Now</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleEditPatient}>
                <Stack spacing={1} direction="row">
                  <EditIcon />
                  <span>Edit</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleDeletePatient} sx={{ color: 'error.main' }}>
                <Stack spacing={1} direction="row">
                  <DeleteIcon />
                  <span>Delete</span>
                </Stack>
              </MenuItem>
            </Popover>
          </>
        ),
      },
    },
  ];

  return (
    <>
      <Box sx={{ marginBottom: 2}}>
        <Grid container spacing={3} >
          <Grid item xs={10} container>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={AddPatient}>Add Patient</Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <AppGrid
              columns={columns}
              data={patients}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
            <Paging
              page={page - 1}
              perPage={perPage}
              count={totalPages * perPage} 
              onChange={(newPage) => setPage(newPage + 1)}
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
        <Box sx={{ width: 350, padding: 2 }}>
          {selectedPatient && (
            <>
              <Typography variant="h6" gutterBottom>
                Patient Details
              </Typography>
              <Typography>Name: {selectedPatient.fullName}</Typography>
              <Typography>Gender: {selectedPatient.gender}</Typography>
              <Typography>Age: {selectedPatient.age}</Typography>
              <Typography>Area: {selectedPatient.area}</Typography>
              <Button variant="contained" sx={{ marginTop: 2 }} onClick={closeDrawer}>
                Close
              </Button>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
}

export default PatientsContainer;
