import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AppGrid from 'src/components/App/AppGrid';
import { Button } from 'src/components/shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchDoctorsRequest,deleteDoctorRequest } from 'src/modules/app/store/appActions';
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';

const DoctorContainer = () => {
    const [doctors, setDoctors] = useState([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [page, setPage] = useState(1);  
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = storage.get('TOKEN');
        const response = await new Promise((resolve, reject) =>
          dispatch(fetchDoctorsRequest(token,page,perPage,resolve, reject)),
        );
        if (response && response.data) {
          setDoctors(response.data);
          setTotalPages(response.meta.totalPages); 
        }
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };

    fetchData();
  }, [dispatch,page,perPage]);

  const handleActionMenu = (event, id) => {
    console.log("Opening action menu for doctor ID:", id);
    setOpen(event.currentTarget);
    setSelectedDoctorId(id); 
  };
  

  const handleDeleteDoctor = async () => {
    const response = await new Promise((resolve, reject) =>
      dispatch(deleteDoctorRequest(selectedDoctorId, resolve, reject)),
    );
    if (response) {
      setDoctors(doctors.filter((doctor) => doctor.id !== selectedDoctorId));
      handleCloseMenu();
      toast.success('Doctor Deleted Successfully');
    }
  };

  const addDoctor = () => {
    navigate('/doctor');
  };

  const handleEditDoctor = () => {
    if (selectedDoctorId) {
      navigate(`/doctor/${selectedDoctorId}`);
    } else {
      toast.error('Doctor information is missing');
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const columns = [
    { label: 'Full Name', name: 'fullName' },
    { label: 'Email', name: 'email' },
    { label: 'Contact Number', name: 'contactNo' },
    { label: 'Usertype', name: 'userType' },
    { label: 'Status', name: 'status' },
    {
      label: 'Tenant Name',
      name: 'tenantId.name',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const tenantId = doctors[tableMeta.rowIndex]?.tenantId;
          return tenantId ? tenantId.name : 'N/A';
        },
      },
    },
    {
      label: '',
      name: '',
      options: {
        customBodyRenderLite: (dataIndex) => (
          <>
            <IconButton onClick={(event) => handleActionMenu(event, doctors[dataIndex]?.id)}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={!!open}
              anchorEl={open}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{ sx: { width: 140 } }}
            >
              <MenuItem onClick={handleEditDoctor}>
                <Stack spacing={1} direction="row">
                  <EditIcon />
                  <span>Edit</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleDeleteDoctor} sx={{ color: 'error.main' }}>
                <Stack spacing={1} direction="row">
                  <DeleteIcon />
                  <span>Delete</span>
                </Stack>
              </MenuItem>
            
            </Popover>
          </>
        )
        
      },
    },
  ];

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={10} container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={addDoctor}>
                Add Doctor
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <AppGrid
              columns={columns}
              data={doctors}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
             <Paging
              page={page - 1}
              perPage={perPage}
              count={totalPages * perPage} 
              onChange={(newPage) => setPage(newPage + 1)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DoctorContainer;
