import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AppGrid from 'src/components/App/AppGrid';
import { Button } from 'src/components/shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PickerWithButtonField from 'src/components/App/DatePicker';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddTaskIcon from '@mui/icons-material/AddTask';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTokenAppointmentRequest, deleteTokenAppointmentRequest } from 'src/modules/app/store/appActions';
import storage from 'src/utils/storageUtils';
import dayjs from 'dayjs';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';

function AppointmentContainer() {
  const [open, setOpen] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [tokenAppointments, setTokenAppointments] = useState([]);
  const [page, setPage] = useState(1);  
const [perPage, setPerPage] = useState(10);
const [totalPages, setTotalPages] = useState(0);
  const [selectedTokenAppointmentId, setSelectedTokenAppointmentId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN');
      const response = await new Promise((resolve, reject) =>
        dispatch(fetchTokenAppointmentRequest(token, page, perPage, resolve, reject)),
      );
      if (response && response.data) {
        setTokenAppointments(response.data);
        setTotalPages(response.meta.totalPages); 
      }
    };
  
    fetchData();
  }, [dispatch, page, perPage]);


  const handleActionMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedTokenAppointmentId(id);
  };

  const handleDeleteTokenAppointment = async () => {
    try {
      await new Promise((resolve, reject) =>
        dispatch(deleteTokenAppointmentRequest(selectedTokenAppointmentId, resolve, reject)),
      );
      setTokenAppointments(tokenAppointments.filter((appointment) => appointment.id !== selectedTokenAppointmentId));
      handleMenuClose();
      toast.success('Appointment Deleted Successfully')
    } catch (error) {
      console.error('Failed to delete appointment:', error);
    }
  };

  const handleMenuClose = () => {
    setOpen(null);
  };

  const handleViewDetails = (appointment) => {
    setSelectedAppointment(appointment);
    setDrawerOpen(true);
    handleMenuClose();
  };

  const addAppointment = () => {
    navigate('/appointment');
  };

  const handleEditAppointment = () => {
    if (selectedTokenAppointmentId) {
      navigate(`/appointment/${selectedTokenAppointmentId}`);
    } else {
      toast.error('appointment information is missing');
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedAppointment(null);
  };

  const columns = [
    {
      label: 'Sr.No',
      name: 'index',
      width: '30%',
      options: {
        customBodyRenderLite: (dataIndex) => dataIndex + 1,
      },
    },
    {
      label: 'Name',
      name: 'fullName',
    },
    {
      label: 'Age',
      name: 'age',
    },
    {
      label: 'Mobile Number',
      name: 'contactNo',
    },
    {
      label: 'Date',
      name: 'date',
      options: {
        customBodyRender: (date) => dayjs(date).format('DD MMM YYYY'),
      },
    },
    {
      label: 'Time',
      name: 'time',
      options: {
        customBodyRender: (time) => dayjs(time).format('hh:mm A'),
      },
    },
    {
      label: 'Symptoms',
      name: 'addnotes',
    },
    {
      label: '',
      name: '',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const appointment = tokenAppointments[dataIndex];
          return (
            <>
              <IconButton onClick={(event) => handleActionMenu(event, appointment.id)}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: { width: 170 },
                }}
              >
                <MenuItem>
                  <Stack spacing={1} direction="row">
                    <EditIcon />
                    <span>Change Status</span>
                  </Stack>
                </MenuItem>
                <MenuItem>
                  <Stack spacing={1} direction="row">
                    <CheckCircleIcon />
                    <span>Attend Now</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={() => handleViewDetails(appointment)}>
                  <Stack spacing={1} direction="row">
                    <VisibilityIcon />
                    <span>View Details</span>
                  </Stack>
                </MenuItem>
                <MenuItem>
                  <Stack spacing={1} direction="row">
                    <AddTaskIcon />
                    <span>Add Task</span>
                  </Stack>
                </MenuItem>
                <MenuItem>
                  <Stack spacing={1} direction="row">
                    <EventOutlinedIcon />
                    <span>Remind</span>
                  </Stack>
                </MenuItem>
                <MenuItem>
                  <Stack spacing={1} direction="row">
                    <BookmarkIcon />
                    <span>Follow Up</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleEditAppointment}>
                  <Stack spacing={1} direction="row">
                    <EditIcon />
                    <span>Edit</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleDeleteTokenAppointment} sx={{ color: 'error.main' }}>
                  <Stack spacing={1} direction="row">
                    <DeleteIcon />
                    <span>Delete</span>
                  </Stack>
                </MenuItem>
              </Popover>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid container spacing={3} >
          <Grid item xs={10} container>
            <Grid item xs={6}>
              <Stack spacing={1} direction="row">
                <Button variant="contained">Today</Button>
                <Button variant="contained">Tomorrow</Button>
                <Button variant="contained">Overmorrow</Button>
                <PickerWithButtonField />
              </Stack>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={addAppointment} variant="contained">
                Add Appointment
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          marginBottom: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <AppGrid
              columns={columns}
              data={tokenAppointments}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
             <Paging
              page={page - 1}
              perPage={perPage}
              count={totalPages * perPage} 
              onChange={(newPage) => setPage(newPage + 1)}
            />
          </Grid>
        </Grid>
      </Box>

      <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
        <Box sx={{ width: 300, padding: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            Appointment Details
          </Typography>
          {selectedAppointment && (
            <>
              <Typography variant="body1">
                <strong>Name:</strong> {selectedAppointment.patientName}
              </Typography>
              <Typography variant="body1">
                <strong>Age:</strong> {selectedAppointment.age}
              </Typography>
              <Typography variant="body1">
                <strong>Mobile Number:</strong> {selectedAppointment.contactNo}
              </Typography>
              <Typography variant="body1">
                <strong>Date:</strong> {dayjs(selectedAppointment.date).format('DD MMM YYYY')}
              </Typography>
              <Typography variant="body1">
                <strong>Time:</strong> {dayjs(selectedAppointment.time).format('hh:mm A')}
              </Typography>
              <Typography variant="body1">
                <strong>Symptoms:</strong> {selectedAppointment.addnotes}
              </Typography>
            </>
          )}
          <Button variant="contained" onClick={closeDrawer} sx={{ marginTop: 2 }}>
            Close
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

export default AppointmentContainer;
