import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Typography, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import { addDoctorRequest, updateDoctorRequest, fetchDoctorByIdRequest } from '../../store/appActions';
import { FormBox } from '../doctor/style';
import { Button } from 'src/components/shared';
import { useSnackbar } from 'notistack';
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';
import { useParams, useNavigate } from 'react-router-dom';

const staticTenants = [
  { id: '66ffc425fe731b60309f9127', name: 'demo' },
  { id: 'otherTenantId', name: 'otherTenant' },
];

function AddDoctor() {
  const dispatch = useDispatch();
  const tenants = useSelector((state) => state.app.tenants);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    fullName: '',
    email: '',
    contactNo: '',
    password: '',
    userType: 'doctor',
    tenantId: '',
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        // await new Promise((resolve, reject) => {
        //   dispatch(fetchTenantsRequest(resolve, reject));
        // });
        if (id) {
          await new Promise((resolve, reject) => {
            dispatch(fetchDoctorByIdRequest(id, (doctor) => {
              setInitialValues({
                fullName: doctor.fullName || '',
                email: doctor.email || '',
                contactNo: doctor.contactNo || '',
                password: '',
                userType: 'doctor',
                tenantId: doctor.tenantId || '',
              });
            }, reject));
          });
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to load data:', error);
        enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, id, enqueueSnackbar]);
  const handleSubmit = async (values, { resetForm }) => {
    const { password, ...doctorData } = values;
    const doctor = {
      ...doctorData,
      ...(id ? {} : { password }),
    };
    try {
      if (id) {
        await new Promise((resolve, reject) => {
          dispatch(updateDoctorRequest(id, doctor, resolve, reject));
        });
        enqueueSnackbar('Doctor updated successfully', { variant: 'success' });
      } else {
        await new Promise((resolve, reject) => {
          dispatch(addDoctorRequest(doctor, resolve, reject));
        });
        enqueueSnackbar('Doctor added successfully', { variant: 'success' });
      }
      resetForm();
      navigate('/doctors');
    } catch (error) {
      console.error('Failed to save doctor:', error);
      enqueueSnackbar('Failed to save doctor', { variant: 'error' });
    }
  };
  const tenantOptions = useMemo(() => (
    [...staticTenants,].map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
    }))
  ), [tenants]);
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Please enter the doctor's full name!"),
    email: Yup.string()
      .email('Invalid email format')
      .required("Please enter the doctor's email!"),
    contactNo: Yup.string()
      .matches(/^\d{10}$/, 'Contact number must be a valid 10-digit number')
      .required("Please enter the doctor's contact number!"),
    password: id
      ? Yup.string()
      : Yup.string()
          .required('Please enter a password!')
          .min(8, 'Password must be at least 8 characters long')
          .matches(/^[a-zA-Z0-9]{8,30}$/, 'Password must contain only alphanumeric characters'),
    tenantId: Yup.string().required('Please select tenant ID!'),
  });
  return (
    <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
                {id ? 'Edit Doctor' : 'Add Doctor'}
              </Typography>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Full Name
                </Typography>
                <Tooltip title="Enter doctor's full name">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="fullName"
                    value={values.fullName}
                    onChange={handleChange}
                    error={touched.fullName && Boolean(errors.fullName)}
                    helperText={touched.fullName && errors.fullName}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Email
                </Typography>
                <Tooltip title="Enter doctor's email">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Contact Number
                </Typography>
                <Tooltip title="Enter doctor's contact number">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="contactNo"
                    value={values.contactNo}
                    onChange={handleChange}
                    error={touched.contactNo && Boolean(errors.contactNo)}
                    helperText={touched.contactNo && errors.contactNo}
                  />
                </Tooltip>
              </Grid>
              {!id && (
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Password
                  </Typography>
                  <Tooltip title="Enter a password">
                    <InputComponent
                      variant="outlined"
                      fullWidth
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Tooltip>
                </Grid>
              )}
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Tenant ID
                </Typography>
                <Tooltip title="Select tenant ID">
                  <Select
                    variant="outlined"
                    fullWidth
                    name="tenantId"
                    value={values.tenantId}
                    onChange={(e) => setFieldValue('tenantId', e.target.value)}
                    options={tenantOptions}
                    error={touched.tenantId && Boolean(errors.tenantId)}
                    helperText={touched.tenantId && errors.tenantId}
                  />
                </Tooltip>
              </Grid>
              <Grid item container justifyContent="center" sx={{ marginTop: '20px' }}>
                <Button variant="contained" type="submit" disabled={!isValid}>
                  {id ? 'Update Doctor' : 'Add Doctor'}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </FormBox>
  );
}
export default AddDoctor;