import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Stack,
  Popover,
  IconButton,
  Grid,
  Box,
  Drawer,
  Typography,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AppGrid from 'src/components/App/AppGrid';
import PickerWithButtonField from 'src/components/App/DatePicker';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNotificationSettingRequest, deleteNotificationSettingRequest } from 'src/modules/app/store/appActions';
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';


function NotificationSettingsContainer() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedNotificationSetting, setSelectedNotificationSetting] = useState();
  const [selectedNotificationSettingId, setSelectedNotificationSettingId] = useState(null);
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [page, setPage] = useState(1);  
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const clinic = useSelector((state) => state.app.clinic);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN');
      const response = await new Promise((resolve, reject) =>
        dispatch(fetchNotificationSettingRequest(token,page,perPage, resolve, reject)),
      );
      if (response && response.data) {
        setNotificationSettings(response.data);
        setTotalPages(response.meta.totalPages)
      }
    };

    fetchData();
  }, [dispatch,page,perPage]);

  const handleActionMenu = (event, id) => {
    console.log('Opening action menu for notificationSetting ID:', id);
    setOpen(event.currentTarget);
    setSelectedNotificationSettingId(id);
  };

  const handleDeleteNotificationSetting = async () => {
    const response = await new Promise((resolve, reject) =>
      dispatch(deleteNotificationSettingRequest(selectedNotificationSettingId, resolve, reject)),
    );
    if (response) {
      setNotificationSettings(notificationSettings.filter((notificationSetting) => notificationSetting.id !== selectedNotificationSettingId));
      handleCloseMenu();
      toast.success('NotificationSetting Deleted Successfully');
      // console.log('NotificationSetting deleted successfully');
    } else {
      console.log('Failed to delete notificationSetting');
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const AddNotificationSetting = (event) => {
    navigate('/notificationSetting');
  };

  const handleViewDetails = (dataIndex) => () => {
    setSelectedNotificationSetting(notificationSettings[dataIndex]);
    setDrawerOpen(true);
    handleCloseMenu();
  };

  // const handleAttendNow = () => {
  //   handleCloseMenu();
  //   navigate(`/${clinic.clinicId}/${clinic.doctorId}`);
  // };

  const handleEditNotificationSetting = () => {
    if (selectedNotificationSettingId) {
      navigate(`/notificationSetting/${selectedNotificationSettingId}`);
    } else {
      toast.error('notification setting information is missing');
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedNotificationSetting(null);
  };

  const columns = [
    { label: 'Doctor Name', name: 'userName' },
    {
      label: 'Push Notification',
      name: 'pushNotification',
      options: {
        customBodyRenderLite: (dataIndex) => (
          notificationSettings[dataIndex]?.pushNotification ? 'Yes' : 'No'
        ),
      },
    },
    {
      label: 'Web Notification',
      name: 'webNotification',
      options: {
        customBodyRenderLite: (dataIndex) => (
          notificationSettings[dataIndex]?.webNotification ? 'Yes' : 'No'
        ),
      },
    },
    {
      label: 'WhatsApp Notification',
      name: 'whatsappNotification',
      options: {
        customBodyRenderLite: (dataIndex) => (
          notificationSettings[dataIndex]?.whatsappNotification ? 'Yes' : 'No'
        ),
      },
    },
    {
      label: 'Email Notification',
      name: 'emailNotification',
      options: {
        customBodyRenderLite: (dataIndex) => (
          notificationSettings[dataIndex]?.emailNotification ? 'Yes' : 'No'
        ),
      },
    },
    {
      label: 'SMS Notification',
      name: 'smsNotification',
      options: {
        customBodyRenderLite: (dataIndex) => (
          notificationSettings[dataIndex]?.smsNotification ? 'Yes' : 'No'
        ),
      },
    },
    {
      label: '',
      name: '',
      options: {
        customBodyRenderLite: (dataIndex) => (
          <>
            <IconButton onClick={(event) => handleActionMenu(event, notificationSettings[dataIndex]?.id)}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{ sx: { width: 170 } }}
            >
              <MenuItem onClick={handleEditNotificationSetting}>
                <Stack spacing={1} direction="row">
                  <EditIcon />
                  <span>Edit</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleDeleteNotificationSetting} sx={{ color: 'error.main' }}>
                <Stack spacing={1} direction="row">
                  <DeleteIcon />
                  <span>Delete</span>
                </Stack>
              </MenuItem>
            </Popover>
          </>
        ),
      },
    },
  ];
  

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={10} container>
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={AddNotificationSetting}>
                Add NotificationSettings
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2}}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <AppGrid
              columns={columns}
              data={notificationSettings}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
            <Paging
              page={page - 1}
              perPage={perPage}
              count={totalPages * perPage} 
              onChange={(newPage) => setPage(newPage + 1)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default NotificationSettingsContainer;
