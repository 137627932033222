import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AppGrid from 'src/components/App/AppGrid';
import { Button } from 'src/components/shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchPatientInfosRequest,deletePatientInfoRequest } from '../../store/appActions';
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';

function PatientInfoContainer() {
  const [patientinfos, setPatientInfos] = useState([]);
  const [selectedPatientInfoId, setSelectedPatientInfoId] = useState(null);
  const [page, setPage] = useState(1);  
const [perPage, setPerPage] = useState(10);
const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN');
      try {
        const response = await new Promise((resolve, reject) =>
          dispatch(fetchPatientInfosRequest(token,page,perPage, resolve, reject)),
        );
        if (response && response.data) {
          setPatientInfos(response.data);
          setTotalPages(response.meta.totalPages); 
        } else {
          console.error("No patient data available.");
        }
      } catch (error) {
        console.error("Error while fetching patient data:", error);
      }
    };
  
    fetchData();
  }, [dispatch,page,perPage]);
  
  const handleActionMenu = (event, id) => {
    console.log("Opening action menu for patientinfo ID:", id); 
    setOpen(event.currentTarget);
    setSelectedPatientInfoId(id); 
  };

  const handleDeletePatientInfo = async () => {
    const response = await new Promise((resolve, reject) =>
      dispatch(deletePatientInfoRequest(selectedPatientInfoId, resolve, reject)),
    );
    if (response) {
      setPatientInfos(patientinfos.filter((patientinfo) => patientinfo.id !== selectedPatientInfoId));
      handleCloseMenu();
      toast.success('PatientInfo Deleted Successfully');
    }
  };

  const addPatientInfo = () => {
    navigate('/patientinfo')
  };

  const handleEditPatientInfo = () => {
    if (selectedPatientInfoId) {
      navigate(`/patientinfo/${selectedPatientInfoId}`);
    } else {
      toast.error('PatientInfo information is missing');
    }
  };

  const columns = [
    {
      label: 'Weight',
      name: 'weight',
    },
    {
      label: 'Height',
      name: 'height',
    },
    {
        label: 'Patient Name',
        name: 'patientName'
      },
    {
      label: 'User Name',
      name: 'userName',
    },
   
    {
      label: () => {
        <></>;
      },
      name: 'action',
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <IconButton onClick={(event) => handleActionMenu(event, patientinfos[dataIndex]?.id)}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: { width: 140 },
                }}
              >
                <MenuItem onClick={handleEditPatientInfo}>
                  <Stack spacing={1} direction="row">
                    <EditIcon />
                    <span>Edit</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleDeletePatientInfo} sx={{ color: 'error.main' }}>
                  <Stack spacing={1} direction="row">
                    <DeleteIcon />
                    <span>Delete</span>
                  </Stack>
                </MenuItem>
              </Popover>
            </>
          );
        },
      },
    },
  ];

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={10} container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={addPatientInfo} variant="contained">Add PatientInfo</Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <AppGrid
              columns={columns}
              data={patientinfos}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
             <Paging
              page={page - 1}
              perPage={perPage}
              count={totalPages * perPage} 
              onChange={(newPage) => setPage(newPage + 1)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PatientInfoContainer;

// customBodyRender: (value) => {
//   if (Array.isArray(value)) {
//     return value.join(', ');
//   }
//   return value; 
// },
// },