import React, { useState, useEffect, useMemo } from "react";
import { ClinicBox, ProfileBackBox } from "../doctor/style";
import {
    Avatar,
    Grid, Typography, IconButton, Box, Divider, FormControlLabel, Tabs, Tab, AppBar,
    MenuItem
} from "@mui/material";
import { Formik } from 'formik';
import Select from 'src/components/shared/Form/Select';
import InputComponent from 'src/components/shared/Form/Input';
import AddAPhotoTwoToneIcon from '@mui/icons-material/AddAPhotoTwoTone';
import CheckboxInput from 'src/components/shared/Form/Checkbox';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Button } from 'src/components/shared';
import SwitchComponent from "src/components/shared/Form/Switch";
// import qrCodeImage from 'src/assets/images/auth/qrcode.png';
import { useForm, Controller } from 'react-hook-form';
import InputAutocomplete from 'src/components/shared/Form/AutoComplete';
import AppTimePicker from 'src/components/shared/Form/Timepicker';
import { addDoctorProfileRequest, fetchDoctornameRequest } from "../../store/appActions";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from "notistack";
import InputSelect from "src/components/shared/Form/Select";



function DoctorProfile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const doctors = useSelector((state) => state.app.doctors);
    const { enqueueSnackbar } = useSnackbar();
    const [initialValues, setInitialValues] = useState({
        id: '',
        personalInfo: {
            profileTitle: '',
            title: '',
            fullName: '',
            displayName: '',
            profilePic: '',
            profileBannerPic: '',
            homeAddress: '',
            languagesSpoken: '',
            aboutPersonal: '',
            status: '',
            user: null
        },
        loginInfo: {
            email: '',
            contactNumber: '',
            username: '',
            biometric: '',
            status: '',
            user: null,
        },
        professionalInfo: {
            experience: '',
            licenceInfo: '',
            professionalExperience: '',
            challenges: '',
            awardsAchievements: '',
            specialProcedure: '',
            requestToPatients: '',
            status: '',
            user: null,
        },
        educationalInfo: {
            schoolInformation: '',
            collegeInformation: '',
            specialisationInformation: '',
            status: '',
            user: null,
        },
        consulting: {
            teleMedicines: 'No',
            clinicMeet: 'No',
            homeVisit: 'No',
            emergencyAvailability: 'No',
            onePatientAverageTime: '',
            gapBetweenAppointments: '',
            collectPatientsInfo: 'No',
            newCaseConsultingFees: '',
            oldCaseConsultingFees: '',
            noteForPatientsOnUnavailability: 'No',
            insuranceAccepting: 'No',
            governmentYojana: 'No',
            publishProfileOnline: 'No',
            acceptPatientsOnline: 'No',
            status: '',
            user: null,
        },
        // futureOptions: {
        //     currentStatus: '',
        //     interestInOutsourcingMarketing: false,
        //     interestInOutsourcingSupport: false,
        //     interestInOutsourcingSocialMedia: false,
        //     interestInCollaborationWithPharmacies: false,
        //     interestInCollaborationWithLaboratories: false,
        //     interestInCollaborationWithSuppliers: false,
        //     interestInAnalyticsReports: false,
        //     associatedClinics: [],
        //     status: '',
        //     user: null,
        // },
        seoPurpose: {
            profileTitle: '',
            profileMetaKeywords: '',
            profileDescription: '',
            footerText: '',
            topKeywords: '',
            status: '',
            user: null,
        },
        // linkedDevices: [],
        links: [{
            link: '',
            linkTitle: '',
            status: '',
            user: null,
        }],
        // photos: [],
        // doctorTimings: {
        //     timings: [],
        //     acceptingAppointments: true,
        //     user:null
        // },
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await new Promise((resolve, reject) => {
                    dispatch(fetchDoctornameRequest(resolve, reject));
                });

                setIsLoading(false);
            } catch (error) {
                console.error('Failed to load data:', error);
                enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, enqueueSnackbar]);


    const titleOptions = [
        { label: 'Dr.', value: 'Dr.' },
        { label: 'Mr.', value: 'Mr.' },
        { label: 'Mrs.', value: 'Mrs.' },
        { label: 'Miss.', value: 'miss' },
    ];

    const languageOptions = [
        { label: 'English', value: 'english' },
        { label: 'Hindi', value: 'hindi' },
        { label: 'Gujrati', value: 'gujrati' },
    ];

    const gapBetweenAppointmentsOptions = [
        { label: '5 Min', value: '5' },
        { label: '10 Min', value: '10' },
        { label: '15 Min ', value: '15' },
        { label: '20 Min', value: '20' },
    ];

    const averagePatientTimeOptions = [
        { label: '5 Min', value: '5' },
        { label: '10 Min', value: '10' },
        { label: '15 Min ', value: '15' },
        { label: '20 Min', value: '20' },
    ];

    const days = [
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' },
        { label: 'Sunday', value: 'Sunday' },
        { label: 'Monday-Friday', value: 'Monday-Friday' },
        { label: 'Saturday-Sunday', value: 'Saturday-Sunday' },

    ];

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({
            label: doctor.name,
            value: doctor.id,
        }))
    ), [doctors]);


    const [image, setImage] = useState(null);
    const handleIconClick = (inputId) => {
        document.getElementById(inputId).click();
    };
    const handleImageUpload = (event,
        type) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            setProfileInfo({ ...profileInfo, [type]: reader.result });
        };
    };
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const [profileInfo, setProfileInfo] = useState({
        title: '', fullName: '', displayName: '', profilePic: '', bannerPic: '', address: '', languages: '', about: ''
    });

    // reader.onload = () => {
    //     setProfileInfo({ ...profileInfo, [type]: reader.result });
    // };

    const handleNext = () => {
        setSelectedTab(selectedTab + 1);
    };

    const handleImageUploaded = (event) => {
        const files = Array.from(event.target.files);
        const newImages = files.map(file => URL.createObjectURL(file));
        setImages(prevImages => [...prevImages, ...newImages]);
    };

    const [images, setImages] = useState([]);

    const handlePrevious = () => {
        setSelectedTab(selectedTab - 1);
    };

    const [openingHours, setOpeningHours] = useState([
        { day: '', startTime: '', endTime: '' },
    ]);
    const [appointmentAccepting, setAppointmentAccepting] = useState(true);
 
    const handleAddOpeningHour = () => {
        setInitialValues((prev) => ({
            ...prev,
            doctorTimings: {
                ...prev.doctorTimings,
                timings: [...prev.doctorTimings.timings, { day: '', startTime: '', endTime: '' }],
            },
        }));
    };

    const handleRemoveOpeningHour = (index) => {
        setInitialValues((prev) => ({
            ...prev,
            doctorTimings: {
                ...prev.doctorTimings,
                timings: prev.doctorTimings.timings.filter((_, i) => i !== index),
            },
        }));
    };

    const handleChangeOpeningHour = (index, field, value) => {
        const updatedTimings = [...initialValues.doctorTimings.timings];
        updatedTimings[index][field] = value;
        setInitialValues((prev) => ({
            ...prev,
            doctorTimings: {
                ...prev.doctorTimings,
                timings: updatedTimings,
            },
        }));
    };

    const handleAppointmentAcceptingChange = (e) => {
        setInitialValues((prev) => ({
            ...prev,
            doctorTimings: {
                ...prev.doctorTimings,
                acceptingAppointments: e.target.checked,
            },
        }));
    };

    const [linkForms, setLinkForms] = useState([{ link: '', linkTitle: '' }]);


    const handleAddLinkForm = () => {
        setLinkForms([...linkForms, { link: '', linkTitle: '' }]);
    };

    const handleRemoveLinkForm = (index) => {
        setLinkForms(linkForms.filter((_, i) => i !== index));
    };
    


    const handleSubmit = async (values, { resetForm }) => {
        const doctorProfile = {
            ...values,
            personalInfo: {
                ...values.personalInfo,
                title: values.title,
                fullName: values.fullName,
                displayName: values.displayName,
                languagesSpoken: values.languagesSpoken,
                homeAddress: values.homeAddress,
                aboutPersonal: values.aboutPersonal,
                status: 'ACTIVE',
            },
            loginInfo: {
                email: values.email,
                contactNumber: values.contactNumber,
                username: values.username,
                biometric: values.biometric,
                status: 'ACTIVE',
            },
            professionalInfo: {
                experience: values.experience,
                licenceInfo: values.licenceInfo,
                professionalExperience: values.professionalExperience,
                challenges: values.challenges,
                awardsAchievements: values.awardsAchievements,
                specialProcedure: values.specialProcedure,
                requestToPatients: values.requestToPatients,
                status: 'ACTIVE',
            },
            educationalInfo: {
                schoolInformation: values.schoolInformation,
                collegeInformation: values.collegeInformation,
                specialisationInformation: values.specialisationInformation,
                status: 'ACTIVE',  
            },
            doctorTimings: {
                day: values.day,
                startTime: values.startTime,
                endTime: values.endTime,
                status: 'ACTIVE',
            },
            consulting: {
                teleMedicines: values.teleMedicines,
                clinicMeet: values.clinicMeet,
                homeVisit: values.homeVisit,
                emergencyAvailability: values.emergencyAvailability,
                onePatientAverageTime: values.onePatientAverageTime,
                gapBetweenAppointments: values.gapBetweenAppointments,
                collectPatientsInfo: values.collectPatientsInfo,
                newCaseConsultingFees: values.newCaseConsultingFees,
                oldCaseConsultingFees: values.oldCaseConsultingFees,
                noteForPatientsOnUnavailability: values.noteForPatientsOnUnavailability,
                insuranceAccepting: values.insuranceAccepting,
                governmentYojana: values.governmentYojana,
                publishProfileOnline: values.publishProfileOnline,
                acceptPatientsOnline: values.acceptPatientsOnline,
                status: 'ACTIVE',
            },
            links: {
                link: values.link,
                linkTitle: values.linkTitle,
                status: 'ACTIVE',
            },
            seoPurpose: {
                profileTitle: values.profileTitle,
                profileMetaKeywords: values.profileMetaKeywords,
                profileDescription: values.profileDescription,
                footerText: values.footerText,
                topKeywords: values.topKeywords,
                status: 'ACTIVE',
            },
        };

        try {
            console.log('Submitting:', doctorProfile);
            await new Promise((resolve, reject) => {
                dispatch(addDoctorProfileRequest(doctorProfile, resolve, reject));
            });
            enqueueSnackbar('Profile added successfully', { variant: 'success' });
            resetForm();
            navigate('/doctorProfiles');
        } catch (error) {
            console.error('Failed to save profile:', error.response?.data || error.message);
            enqueueSnackbar(error.response?.data.message || 'Failed to save profile', { variant: 'error' });
        }
    };


    return (
        <ClinicBox>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Typography variant="h3" color='#393A96' fontWeight='bold' textAlign='center' sx={{ position: 'relative', top: '15px' }}>
                            Doctor Profile
                        </Typography>
                        <Divider sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }} justifyContent="center" alignItems="center">
                            <Grid item>
                                <ProfileBackBox> 
                                    <Avatar src={image} sx={{ width: 100, height: 100, marginTop: '80px' }} />
                                    <AddAPhotoTwoToneIcon
                                        onClick={() => handleIconClick('profilePicInput')}
                                        sx={{ position: 'relative', top: '80px', right: "30px", color: '#393A96', cursor: 'pointer' }}
                                    />
                                    <input
                                        id="profilePicInput"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleImageUpload(e, setImage)}
                                         />
                                </ProfileBackBox>
                            </Grid>
                        </Grid>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={selectedTab}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="Personal Info" />
                                <Tab label="Login Info" />
                                <Tab label="Professional Info" />
                                <Tab label="Education" />
                                <Tab label="Availability" />
                                <Tab label="Linked Devices" />
                                <Tab label="Consulting Options" />
                                <Tab label="SEO" />
                                <Tab label="Links" />
                                <Tab label="Photos" />
                            </Tabs>
                        </AppBar>

                        {selectedTab === 0 && (
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                <Box component="form">
                                    <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Doctor
                                            </Typography>
                                            <Select
                                                variant="outlined"
                                                fullWidth
                                                name="id"
                                                value={values.id}
                                                onChange={(e) => setFieldValue('id', e.target.value)}
                                                options={doctorOptions}
                                                placeholder="Select a id"
                                                error={touched.id && Boolean(errors.id)}
                                                helperText={touched.id && errors.id}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Title
                                            </Typography>
                                            <Select
                                                variant="outlined"
                                                fullWidth
                                                name="title"
                                                value={values.title}
                                                onChange={handleChange}
                                                options={titleOptions}
                                                placeholder="Select a title"
                                                error={touched.title && Boolean(errors.title)}
                                                helperText={touched.title && errors.title}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Full Name
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="fullName"
                                                value={values.fullName}
                                                onChange={handleChange}
                                                error={touched.fullName && Boolean(errors.fullName)}
                                                helperText={touched.fullName && errors.fullName}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Display Name
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="displayName"
                                                value={values.displayName}
                                                onChange={handleChange}
                                                error={touched.displayName && Boolean(errors.displayName)}
                                                helperText={touched.displayName && errors.displayName}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Language Spoken
                                            </Typography>
                                            <Select
                                                variant="outlined"
                                                fullWidth
                                                name="languagesSpoken"
                                                value={values.languagesSpoken}
                                                onChange={handleChange}
                                                options={languageOptions}
                                                placeholder="Select a languagesSpoken"
                                                error={touched.languagesSpoken && Boolean(errors.languagesSpoken)}
                                                helperText={touched.languagesSpoken && errors.languagesSpoken}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Home Address
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="homeAddress"
                                                value={values.homeAddress}
                                                onChange={handleChange}
                                                error={touched.homeAddress && Boolean(errors.homeAddress)}
                                                helperText={touched.homeAddress && errors.homeAddress}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                About
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="aboutPersonal"
                                                value={values.aboutPersonal}
                                                onChange={handleChange}
                                                error={touched.aboutPersonal && Boolean(errors.aboutPersonal)}
                                                helperText={touched.aboutPersonal && errors.aboutPersonal}
                                            />
                                        </Grid>
                                        <Grid container spacing={2} justifyContent="end" sx={{ marginTop: '10px' }}>
                                            <Button variant='contained' onClick={handleNext}>
                                                Next
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}

                        {selectedTab === 1 && (
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                <Box component="form">
                                    <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Email
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                error={touched.email && Boolean(errors.email)}
                                                helperText={touched.email && errors.email}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Contact Number
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="contactNumber"
                                                value={values.contactNumber}
                                                onChange={handleChange}
                                                error={touched.contactNumber && Boolean(errors.contactNumber)}
                                                helperText={touched.contactNumber && errors.contactNumber}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                User Name
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="username"
                                                value={values.username}
                                                onChange={handleChange}
                                                error={touched.username && Boolean(errors.username)}
                                                helperText={touched.username && errors.username}
                                            />
                                        </Grid>

                                        <Grid container spacing={8} justifyContent="end" sx={{ marginTop: '-30px' }}>
                                            <Grid item>
                                                <Button variant='contained' onClick={handlePrevious}>
                                                    Previous
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant='contained' onClick={handleNext}>
                                                    Next
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}

                        {selectedTab === 2 && (
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                <Box component="form">
                                    <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Experience
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="experience"
                                                value={values.experience}
                                                onChange={handleChange}
                                                error={touched.experience && Boolean(errors.aboutPersoexperiencenal)}
                                                helperText={touched.experience && errors.experience}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Licence Info
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="licenceInfo"
                                                value={values.licenceInfo}
                                                onChange={handleChange}
                                                error={touched.licenceInfo && Boolean(errors.licenceInfo)}
                                                helperText={touched.licenceInfo && errors.licenceInfo}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Professional Experience
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="professionalExperience"
                                                value={values.professionalExperience}
                                                onChange={handleChange}
                                                error={touched.professionalExperience && Boolean(errors.professionalExperience)}
                                                helperText={touched.professionalExperience && errors.professionalExperience}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Challenges
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="challenges"
                                                value={values.challenges}
                                                onChange={handleChange}
                                                error={touched.challenges && Boolean(errors.challenges)}
                                                helperText={touched.challenges && errors.challenges}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Awards Achievements
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="awardsAchievements"
                                                value={values.awardsAchievements}
                                                onChange={handleChange}
                                                error={touched.awardsAchievements && Boolean(errors.awardsAchievements)}
                                                helperText={touched.awardsAchievements && errors.awardsAchievements}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Special Procedure
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="specialProcedure"
                                                value={values.specialProcedure}
                                                onChange={handleChange}
                                                error={touched.specialProcedure && Boolean(errors.specialProcedure)}
                                                helperText={touched.specialProcedure && errors.specialProcedure}
                                            />
                                        </Grid>

                                        <Grid container spacing={8} justifyContent="end" sx={{ marginTop: '-30px' }}>
                                            <Grid item>
                                                <Button variant='contained' onClick={handlePrevious}>
                                                    Previous
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant='contained' onClick={handleNext}>
                                                    Next
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}

                        {selectedTab === 3 && (
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                <Box component="form">
                                    <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                School Information
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="schoolInformation"
                                                value={values.schoolInformation}
                                                onChange={handleChange}
                                                error={touched.schoolInformation && Boolean(errors.schoolInformation)}
                                                helperText={touched.schoolInformation && errors.schoolInformation}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                College Information
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="collegeInformation"
                                                value={values.collegeInformation}
                                                onChange={handleChange}
                                                error={touched.collegeInformation && Boolean(errors.collegeInformation)}
                                                helperText={touched.collegeInformation && errors.collegeInformation}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Specialisation Information
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="specialisationInformation"
                                                value={values.specialisationInformation}
                                                onChange={handleChange}
                                                error={touched.specialisationInformation && Boolean(errors.specialisationInformation)}
                                                helperText={touched.specialisationInformation && errors.specialisationInformation}
                                            />
                                        </Grid>
                                        <Grid container spacing={8} justifyContent="end" sx={{ marginTop: '-30px' }}>
                                            <Grid item>
                                                <Button variant='contained' onClick={handlePrevious}>
                                                    Previous
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant='contained' onClick={handleNext}>
                                                    Next
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}

                        {/* {selectedTab === 4 && (
                            <Grid container spacing={2} sx={{ padding: '30px' }}>
                                <Typography variant="h5" fontWeight="bold">Opening Hours</Typography>
                                <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                    {initialValues.doctorTimings.timings.map((openingHour, index) => (
                                        <Grid container spacing={2} key={index}>
                                            <Grid item xs={4}>
                                                <Typography variant="h5" fontWeight="bold">Days</Typography>
                                                <Select
                                                    variant="outlined"
                                                    fullWidth
                                                    name="day"
                                                    value={values.day}
                                                    onChange={handleChange}
                                                    options={days}
                                                    placeholder="Select a day"
                                                    error={touched.day && Boolean(errors.day)}
                                                    helperText={touched.day && errors.day}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="h5" fontWeight="bold">Start Time</Typography>
                                                <AppTimePicker
                                                    variant="outlined"
                                                    fullWidth
                                                    value={openingHour.startTime}
                                                    onChange={(value) => handleChangeOpeningHour(index, 'startTime', value)}
                                                    renderInput={(params) => (
                                                        <InputComponent {...params} />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="h5" fontWeight="bold">End Time</Typography>
                                                <AppTimePicker
                                                    variant="outlined"
                                                    fullWidth
                                                    value={openingHour.endTime}
                                                    onChange={(value) => handleChangeOpeningHour(index, 'endTime', value)}
                                                    renderInput={(params) => (
                                                        <InputComponent {...params} />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button onClick={() => handleRemoveOpeningHour(index)}>Remove</Button>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Grid item xs={2} sx={{ position: "relative", left: '25px' }}>
                                        <Button startIcon={<AddCircle />} onClick={handleAddOpeningHour}>
                                            Add Opening Hour
                                        </Button>
                                    </Grid>

                                    <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ marginTop: 2 }}>
                                        <Grid item>
                                            <Typography variant="h6" fontWeight="bold" display="inline" ml={1}>Accepting Appointments</Typography>
                                            <SwitchComponent
                                                checked={initialValues.doctorTimings.acceptingAppointments}
                                                onChange={handleAppointmentAcceptingChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8} justifyContent="flex-end">
                                        <Grid item>
                                            <Button variant='contained' onClick={handlePrevious}>
                                                Previous
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained' onClick={handleNext}>
                                                Next
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )} */}

                        {selectedTab === 6 && (
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                <Box component="form">
                                    <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                            {[
                                                { name: 'noteForPatientsOnUnavailability', label: 'Not for Patients on Unavailability' },
                                                { name: 'insuranceAccepting', label: 'Insurance Accepting' },
                                                { name: 'governmentYojana', label: 'Government Yojana' },
                                                { name: 'publishProfileOnline', label: 'Publish Profile Online' },
                                                { name: 'acceptPatientsOnline', label: 'Accept Patients Online' },
                                                { name: 'teleMedicines', label: 'TeleMedicine' },
                                                { name: 'homeVisit', label: 'In Person at Home' },
                                                { name: 'emergencyAvailability', label: 'Emergency Availability' },
                                                { name: 'clinicMeet', label: 'In Person at Clinic' },
                                                { name: 'collectPatientsInfo', label: 'Collect Patients Info' },
                                            ].map((field) => (
                                                <Grid item xs={6} key={field.name}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs>
                                                            <Typography variant='h5' fontWeight='bold'>
                                                                {field.label}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <CheckboxInput
                                                                name={field.name}
                                                                checked={values[field.name] === 'Yes'}
                                                                onChange={(checked) => setFieldValue(field.name, checked ? 'Yes' : 'No')}
                                                                sx={{ position: 'relative', right: '68px' }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>

                                        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                                            <Grid item xs={6}>
                                                <Typography variant="h5" fontWeight="bold">
                                                    One Patient Average Time
                                                </Typography>
                                                <InputComponent
                                                    variant="outlined"
                                                    fullWidth
                                                    name="onePatientAverageTime"
                                                    value={values.onePatientAverageTime}
                                                    onChange={handleChange}
                                                    error={touched.onePatientAverageTime && Boolean(errors.onePatientAverageTime)}
                                                    helperText={touched.onePatientAverageTime && errors.onePatientAverageTime}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h5" fontWeight="bold">
                                                    Gap Between Appointments
                                                </Typography>
                                                <InputComponent
                                                    variant="outlined"
                                                    fullWidth
                                                    name="gapBetweenAppointments"
                                                    value={values.gapBetweenAppointments}
                                                    onChange={handleChange}
                                                    error={touched.gapBetweenAppointments && Boolean(errors.gapBetweenAppointments)}
                                                    helperText={touched.gapBetweenAppointments && errors.gapBetweenAppointments}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h5" fontWeight='bold'>
                                                    Gap between Two Appointments
                                                </Typography>
                                                <Select
                                                    variant="outlined"
                                                    fullWidth
                                                    name="gapBetweenAppointments"
                                                    value={values.gapBetweenAppointments}
                                                    onChange={handleChange}
                                                    options={gapBetweenAppointmentsOptions}
                                                    placeholder="Select a gapBetween Appointments"
                                                    error={touched.gapBetweenAppointments && Boolean(errors.gapBetweenAppointments)}
                                                    helperText={touched.gapBetweenAppointments && errors.gapBetweenAppointments}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h5" fontWeight='bold'>
                                                    One Patient Average Taking
                                                </Typography>
                                                <Select
                                                    variant="outlined"
                                                    fullWidth
                                                    name="onePatientAverageTime"
                                                    value={values.onePatientAverageTime}
                                                    onChange={handleChange}
                                                    options={averagePatientTimeOptions}
                                                    placeholder="Select a one Patient Average Time"
                                                    error={touched.onePatientAverageTime && Boolean(errors.onePatientAverageTime)}
                                                    helperText={touched.onePatientAverageTime && errors.onePatientAverageTime}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={8} justifyContent="flex-end" sx={{ marginTop: '30px' }}>
                                            <Grid item>
                                                <Button variant='contained' onClick={handlePrevious}>
                                                    Previous
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button type="submit" variant='contained'>
                                                    Next
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}

                        {selectedTab === 7 && (
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                <Box component="form">
                                    <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Profile Title
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="profileTitle"
                                                value={values.profileTitle}
                                                onChange={handleChange}
                                                error={touched.profileTitle && Boolean(errors.profileTitle)}
                                                helperText={touched.profileTitle && errors.profileTitle}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Profile Meta Keywords
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="profileMetaKeywords"
                                                value={values.profileMetaKeywords}
                                                onChange={handleChange}
                                                error={touched.profileMetaKeywords && Boolean(errors.profileMetaKeywords)}
                                                helperText={touched.profileMetaKeywords && errors.profileMetaKeywords}
                                            />
                                        </Grid>


                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Footer Text
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="footerText"
                                                value={values.footerText}
                                                onChange={handleChange}
                                                error={touched.footerText && Boolean(errors.footerText)}
                                                helperText={touched.footerText && errors.footerText}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Top Keywords
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="topKeywords"
                                                value={values.topKeywords}
                                                onChange={handleChange}
                                                error={touched.topKeywords && Boolean(errors.topKeywords)}
                                                helperText={touched.topKeywords && errors.topKeywords}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Profile Description
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="profileDescription"
                                                value={values.profileDescription}
                                                onChange={handleChange}
                                                error={touched.profileDescription && Boolean(errors.profileDescription)}
                                                helperText={touched.profileDescription && errors.profileDescription}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={8} justifyContent="end" >
                                        <Grid item>
                                            <Button variant='contained' onClick={handlePrevious}>
                                                Previous
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant='contained' onClick={handleNext}>
                                                Next
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        )}

                        {selectedTab === 8 && (
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                {linkForms.map((form, index) => (
                                    <Box key={index} component="form">
                                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Link Title
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="linkTitle"
                                                value={values.linkTitle}
                                                onChange={handleChange}
                                                error={touched.linkTitle && Boolean(errors.linkTitle)}
                                                helperText={touched.linkTitle && errors.linkTitle}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" fontWeight="bold">
                                                Link
                                            </Typography>
                                            <InputComponent
                                                variant="outlined"
                                                fullWidth
                                                name="link"
                                                value={values.link}
                                                onChange={handleChange}
                                                error={touched.link && Boolean(errors.link)}
                                                helperText={touched.link && errors.link}
                                            />
                                        </Grid>
                                            {index === linkForms.length - 1 && (
                                                <Grid item xs={2}>
                                                    <Button
                                                        startIcon={<AddCircle />}
                                                        onClick={handleAddLinkForm}
                                                    >
                                                        Add New Link
                                                    </Button>
                                                </Grid>
                                            )}
                                            {index > 0 && (
                                                <Grid item xs={2} >
                                                    <Button
                                                        startIcon={<AddCircle />}
                                                        onClick={() => handleRemoveLinkForm(index)}
                                                    >
                                                        Remove Link
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                        {index === linkForms.length - 1 && (
                                            <Grid container spacing={8} justifyContent="flex-end">
                                                <Grid item>
                                                    <Button variant='contained' onClick={handlePrevious}>
                                                        Previous
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant='contained' onClick={handleNext}>
                                                        Next
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Box>
                                ))}
                            </Grid>
                        )}

                        {selectedTab === 9 && (
                            <Grid container spacing={2} sx={{ padding: '30px' }}>
                                <Typography variant="h5" fontWeight="bold">Photos</Typography>
                                <Grid item xs={12}>
                                    <Button variant="contained" component="label">
                                        Upload Photos
                                        <input
                                            hidden
                                            accept="image/*"
                                            multiple
                                            type="file"
                                            onChange={handleImageUploaded}
                                        />
                                    </Button>
                                    <ImageList cols={3}>
                                        {images.map((image, index) => (
                                            <ImageListItem key={index}>
                                                <img src={image} alt={`uploaded-${index}`} />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>

                                    <Grid container spacing={8} justifyContent="flex-end">
                                        <Grid item>
                                            <Button variant="contained" onClick={handlePrevious}>
                                                Previous
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" type="submit">
                                                save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}


                    </form>
                )
                }
            </Formik >
        </ClinicBox >
    );
};

export default DoctorProfile;

