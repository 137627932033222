import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Stack,
  Popover,
  IconButton,
  Grid,
  Box,
  Drawer,
  Typography,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AppGrid from 'src/components/App/AppGrid';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStaffsRequest, deleteStaffRequest } from 'src/modules/app/store/appActions'; 
import storage from 'src/utils/storageUtils';
import toast from 'src/utils/toast';
import Paging from 'src/components/App/Paging';

function StaffsContainer() {
  const [open, setOpen] = useState(null);
  const [staffs, setStaffs] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [page, setPage] = useState(1);  
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0); 
  const navigate = useNavigate();
  const clinic = useSelector((state) => state.app.clinics);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN');
      const response = await new Promise((resolve, reject) =>
        dispatch(fetchStaffsRequest(token,page,perPage, resolve, reject)),
      );
      if (response && response.data) {
        setStaffs(response.data);
        setTotalPages(response.meta.totalPages)
      }
    };
    fetchData();
  }, [dispatch,page,perPage]);


  const handleActionMenu = (event, id) => {
    console.log("Opening action menu for staff ID:", id); 
    setOpen(event.currentTarget);
    setSelectedStaffId(id); 
  };


  const handleDeleteStaff = async () => {
    const response = await new Promise((resolve, reject) =>
      dispatch(deleteStaffRequest(selectedStaffId, resolve, reject)),
    );
    if (response) {
      setStaffs(staffs.filter((staff) => staff.id !== selectedStaffId));
      handleCloseMenu();
      toast.success('Staff Deleted Successfully');
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const addStaff = (event) => {
    navigate('/staff')
  };

  const handleViewDetails = (dataIndex) => {
    return () => {
      if (staffs && staffs.length > dataIndex) {
        setSelectedStaff(staffs[dataIndex]);
        setDrawerOpen(true);
        handleCloseMenu();
      } else {
        console.error(`Invalid dataIndex: ${dataIndex}`);
      }
    };
  };

  const handleEditStaff = () => {
    if (selectedStaffId) {
      navigate(`/staff/${selectedStaffId}`);
    } else {
      toast.error('staff information is missing');
    }
  };


  const closeDrawer = () => {
    setDrawerOpen(false);
    setSelectedStaff(null);
  };

  const columns = [
    { label: 'Name', name: 'name' },
    { label: 'Email', name: 'email' },
    { label: 'Role', name: 'role' },
    {
      label: '',
      name: '',
      options: {
        customBodyRenderLite: (dataIndex) => (
          <>
            <IconButton onClick={(event) => handleActionMenu(event, staffs[dataIndex]?.id)}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{ sx: { width: 190 } }}
            >
              <MenuItem onClick={handleViewDetails(dataIndex)}>
                <Stack spacing={1} direction="row">
                  <VisibilityIcon />
                  <span>View Details</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleEditStaff}>
                <Stack spacing={1} direction="row">
                  <EditIcon />
                  <span>Edit</span>
                </Stack>
              </MenuItem>
              <MenuItem onClick={handleDeleteStaff} sx={{ color: 'error.main' }}>
                <Stack spacing={1} direction="row">
                  <DeleteIcon />
                  <span>Delete</span>
                </Stack>
              </MenuItem>
            </Popover>
          </>
        ),
      },
    },
  ];

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={3} >
          <Grid item xs={10} container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={addStaff}>
                Add Staff
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 2}}>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <AppGrid
              columns={columns}
              data={staffs}
              selectableRows="none"
              onFilterChange={() => { }}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => { }}
              onSort={() => { }}
              onClearFilters={() => { }}
              title=""
              titleProps={{}}
              pagingType="table"
            />
            <Paging
              page={page - 1}
              perPage={perPage}
              count={totalPages * perPage} 
              onChange={(newPage) => setPage(newPage + 1)}
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
        <Box sx={{ width: 350, padding: 2 }}>
          {selectedStaff && (
            <>
              <Typography variant="h6" gutterBottom>
                Staff Details
              </Typography>
              <Typography>Name: {selectedStaff.name}</Typography>
              <Typography>Email: {selectedStaff.email}</Typography>
              <Typography>Role: {selectedStaff.role}</Typography>
              <Button variant="contained" sx={{ marginTop: 2 }} onClick={closeDrawer}>
                Close
              </Button>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
}

export default StaffsContainer;