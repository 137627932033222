export const SET_APP_LOADING = 'SET_APP_LOADING';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

//add doctor
export const ADD_DOCTOR_REQUEST = 'ADD_DOCTOR_REQUEST';
export const ADD_DOCTOR_SUCCESS = 'ADD_DOCTOR_SUCCESS';
export const ADD_DOCTOR_FAILURE = 'ADD_DOCTOR_FAILURE';

// For Patient
export const ADD_PATIENT_REQUEST = 'ADD_PATIENT_REQUEST';
export const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS';
export const ADD_PATIENT_FAILURE = 'ADD_PATIENT_FAILURE';

//add tokenappointment
export const ADD_TOKENAPPOINTMENT_REQUEST = 'ADD_TOKENAPPOINTMENT_REQUEST';
export const ADD_TOKENAPPOINTMENT_SUCCESS = 'ADD_TOKENAPPOINTMENT_SUCCESS';
export const ADD_TOKENAPPOINTMENT_FAILURE = 'ADD_TOKENAPPOINTMENT_FAILURE';

//add clinic
export const ADD_CLINIC_REQUEST = 'ADD_CLINIC_REQUEST';
export const ADD_CLINIC_SUCCESS = 'ADD_CLINIC_SUCCESS';
export const ADD_CLINIC_FAILURE = 'ADD_CLINIC_FAILURE';

//add task
export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAILURE = 'ADD_TASK_FAILURE';

// add Staff
export const ADD_STAFF_REQUEST = 'ADD_STAFF_REQUEST';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';
export const ADD_STAFF_FAILURE = 'ADD_STAFF_FAILURE';

//add area
export const ADD_AREA_REQUEST = 'ADD_AREA_REQUEST';
export const ADD_AREA_SUCCESS = 'ADD_AREA_SUCCESS';
export const ADD_AREA_FAILURE = 'ADD_AREA_FAILURE';

//add tag
export const ADD_TAG_REQUEST = 'ADD_TAG_REQUEST';
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS';
export const ADD_TAG_FAILURE = 'ADD_TAG_FAILURE';

//add billing
export const ADD_BILLING_REQUEST = 'ADD_BILLING_REQUEST';
export const ADD_BILLING_SUCCESS = 'ADD_BILLING_SUCCESS';
export const ADD_BILLING_FAILURE = 'ADD_BILLING_FAILURE';

//add permission
export const ADD_PERMISSION_REQUEST = 'ADD_PERMISSION_REQUEST';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const ADD_PERMISSION_FAILURE = 'ADD_PERMISSION_FAILURE';

//add template
export const ADD_TEMPLATE_REQUEST = 'ADD_TEMPLATE_REQUEST';
export const ADD_TEMPLATE_SUCCESS = 'ADD_TEMPLATE_SUCCESS';
export const ADD_TEMPLATE_FAILURE = 'ADD_TEMPLATE_FAILURE';

//add treatment
export const ADD_TREATMENT_REQUEST = 'ADD_TREATMENT_REQUEST';
export const ADD_TREATMENT_SUCCESS = 'ADD_TREATMENT_SUCCESS';
export const ADD_TREATMENT_FAILURE = 'ADD_TREATMENT_FAILURE';

//add medicalrecord
export const ADD_MEDICALRECORD_REQUEST = 'ADD_MEDICALRECORD_REQUEST';
export const ADD_MEDICALRECORD_SUCCESS = 'ADD_MEDICALRECORD_SUCCESS';
export const ADD_MEDICALRECORD_FAILURE = 'ADD_MEDICALRECORD_FAILURE';

//add followup
export const ADD_FOLLOWUP_REQUEST = 'ADD_FOLLOWUP_REQUEST';
export const ADD_FOLLOWUP_SUCCESS = 'ADD_FOLLOWUP_SUCCESS';
export const ADD_FOLLOWUP_FAILURE = 'ADD_FOLLOWUP_FAILURE';

//add notificationsetting
export const ADD_NOTIFICATIONSETTING_REQUEST = 'ADD_NOTIFICATIONSETTING_REQUEST';
export const ADD_NOTIFICATIONSETTING_SUCCESS = 'ADD_NOTIFICATIONSETTING_SUCCESS';
export const ADD_NOTIFICATIONSETTING_FAILURE = 'ADD_NOTIFICATIONSETTING_FAILURE';

//add notification
export const ADD_NOTIFICATION_REQUEST = 'ADD_NOTIFICATION_REQUEST';
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS';
export const ADD_NOTIFICATION_FAILURE = 'ADD_NOTIFICATION_FAILURE';

//add message
export const ADD_MESSAGE_REQUEST = 'ADD_MESSAGE_REQUEST';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_FAILURE = 'ADD_MESSAGE_FAILURE';

//add patientinfo
export const ADD_PATIENTINFO_REQUEST = 'ADD_PATIENTINFO_REQUEST';
export const ADD_PATIENTINFO_SUCCESS = 'ADD_PATIENTINFO_SUCCESS';
export const ADD_PATIENTINFO_FAILURE = 'ADD_PATIENTINFO_FAILURE';

//add message
export const ADD_DOCTORPROFILE_REQUEST = 'ADD_DOCTORPROFILE_REQUEST';
export const ADD_DOCTORPROFILE_SUCCESS = 'ADD_DOCTORPROFILE_SUCCESS';
export const ADD_DOCTORPROFILE_FAILURE = 'ADD_DOCTORPROFILE_FAILURE';

//GET DOCTOR NAME

export const FETCH_DOCTOR_NAMES_REQUEST = 'FETCH_DOCTOR_NAMES_REQUEST';
export const FETCH_DOCTOR_NAMES_SUCCESS = 'FETCH_DOCTOR_NAMES_SUCCESS';
export const FETCH_DOCTOR_NAMES_FAILURE = 'FETCH_DOCTOR_NAMES_FAILURE';

//GET CLINIC NAME
export const FETCH_CLINIC_NAMES_REQUEST = 'FETCH_CLINIC_NAMES_REQUEST';
export const FETCH_CLINIC_NAMES_SUCCESS = 'FETCH_CLINIC_NAMES_SUCCESS';
export const FETCH_CLINIC_NAMES_FAILURE = 'FETCH_CLINIC_NAMES_FAILURE';

//GET PATIENT NAME
export const FETCH_PATIENT_NAMES_REQUEST = 'FETCH_PATIENT_NAMES_REQUEST';
export const FETCH_PATIENT_NAMES_SUCCESS = 'FETCH_PATIENT_NAMES_SUCCESS';
export const FETCH_PATIENT_NAMES_FAILURE = 'FETCH_PATIENT_NAMES_FAILURE';

//get doctor
export const FETCH_DOCTORS_REQUEST = 'FETCH_DOCTORS_REQUEST';
export const FETCH_DOCTORS_SUCCESS = 'FETCH_DOCTORS_SUCCESS';
export const FETCH_DOCTORS_FAILURE = 'FETCH_DOCTORS_FAILURE';


// For getPatients
export const FETCH_PATIENTS_REQUEST = 'FETCH_PATIENTS_REQUEST';
export const FETCH_PATIENTS_SUCCESS = 'FETCH_PATIENTS_SUCCESS';
export const FETCH_PATIENTS_FAILURE = 'FETCH_PATIENTS_FAILURE';

//get staff
export const FETCH_STAFFS_REQUEST = 'FETCH_STAFFS_REQUEST';
export const FETCH_STAFFS_SUCCESS = 'FETCH_STAFFS_SUCCESS';
export const FETCH_STAFFS_FAILURE = 'FETCH_STAFFS_FAILURE';

//get Tasks
export const FETCH_TASKS_REQUEST = 'FETCH_TASKS_REQUEST';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';

//get clinics
export const FETCH_CLINICS_REQUEST = 'FETCH_CLINICS_REQUEST';
export const FETCH_CLINICS_SUCCESS = 'FETCH_CLINICS_SUCCESS';
export const FETCH_CLINICS_FAILURE = 'FETCH_CLINICS_FAILURE';

//get Appointment
export const FETCH_TOKENAPPOINTMENT_REQUEST = 'FETCH_TOKENAPPOINTMENT_REQUEST';
export const FETCH_TOKENAPPOINTMENT_SUCCESS = 'FETCH_TOKENAPPOINTMENT_SUCCESS';
export const FETCH_TOKENAPPOINTMENT_FAILURE = 'FETCH_TOKENAPPOINTMENT_FAILURE';

//get billings
export const FETCH_BILLINGS_REQUEST = 'FETCH_BILLINGS_REQUEST';
export const FETCH_BILLINGS_SUCCESS = 'FETCH_BILLINGS_SUCCESS';
export const FETCH_BILLINGS_FAILURE = 'FETCH_BILLINGS_FAILURE';

//get areas
export const FETCH_AREAS_REQUEST = 'FETCH_AREAS_REQUEST';
export const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS';
export const FETCH_AREAS_FAILURE = 'FETCH_AREAS_FAILURE';

//get TAG
export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

//get TAG
export const FETCH_PATIENTINFOS_REQUEST = 'FETCH_PATIENTINFOS_REQUEST';
export const FETCH_PATIENTINFOS_SUCCESS = 'FETCH_PATIENTINFOS_SUCCESS';
export const FETCH_PATIENTINFOS_FAILURE = 'FETCH_PATIENTINFOS_FAILURE';

//get treatments
export const FETCH_TREATMENTS_REQUEST = 'FETCH_TREATMENTS_REQUEST';
export const FETCH_TREATMENTS_SUCCESS = 'FETCH_TREATMENTS_SUCCESS';
export const FETCH_TREATMENTS_FAILURE = 'FETCH_TREATMENTS_FAILURE';

//get permissions
export const FETCH_PERMISSIONS_REQUEST = 'FETCH_PERMISSIONS_REQUEST';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAILURE = 'FETCH_PERMISSIONS_FAILURE';

//get templates
export const FETCH_TEMPLATES_REQUEST = 'FETCH_TEMPLATES_REQUEST';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAILURE = 'FETCH_TEMPLATES_FAILURE';

//get medicalrecords
export const FETCH_MEDICALRECORDS_REQUEST = 'FETCH_MEDICAL_REQUEST';
export const FETCH_MEDICALRECORDS_SUCCESS = 'FETCH_MEDICAL_SUCCESS';
export const FETCH_MEDICALRECORDS_FAILURE = 'FETCH_MEDICAL_FAILURE';

//get followup
export const FETCH_FOLLOWUPS_REQUEST = 'FETCH_FOLLOWUPS_REQUEST';
export const FETCH_FOLLOWUPS_SUCCESS = 'FETCH_FOLLOWUPS_SUCCESS';
export const FETCH_FOLLOWUPS_FAILURE = 'FETCH_FOLLOWUPS_FAILURE';

//get notificationSetting
export const FETCH_NOTIFICATION_SETTING_REQUEST = 'FETCH_NOTIFICATIONSETTING_REQUEST';
export const FETCH_NOTIFICATION_SETTING_SUCCESS = 'FETCH_NOTIFICATIONSETTING_SUCCESS';
export const FETCH_NOTIFICATION_SETTING_FAILURE = 'FETCH_NOTIFICATIONSETTING_FAILURE';

//get messages
export const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';

//get notifications
export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

//get doctor profile
export const FETCH_DOCTORPROFILES_REQUEST = 'FETCH_DOCTORPROFILES_REQUEST';
export const FETCH_DOCTORPROFILES_SUCCESS = 'FETCH_DOCTORPROFILES_SUCCESS';
export const FETCH_DOCTORPROFILES_FAILURE = 'FETCH_DOCTORPROFILES_FAILURE';

//get CLINIC profile
export const FETCH_CLINICPROFILES_REQUEST = 'FETCH_CLINICPROFILES_REQUEST';
export const FETCH_CLINICPROFILES_SUCCESS = 'FETCH_CLINICPROFILES_SUCCESS';
export const FETCH_CLINICPROFILES_FAILURE = 'FETCH_CLINICPROFILES_FAILURE';

//delete doctor
export const DELETE_DOCTOR_REQUEST = 'DELETE_DOCTOR_REQUEST';
export const DELETE_DOCTOR_SUCCESS = 'DELETE_DOCTOR_SUCCESS';
export const DELETE_DOCTOR_FAILURE = 'DELETE_DOCTOR_FAILURE';

//delete patient
export const DELETE_PATIENT_REQUEST = 'DELETE_PATIENT_REQUEST';
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS';
export const DELETE_PATIENT_FAILURE = 'DELETE_PATIENT_FAILURE';

//delete tokenappointment
export const DELETE_TOKENAPPOINTMENT_REQUEST = 'DELETE_TOKENAPPOINTMENT_REQUEST';
export const DELETE_TOKENAPPOINTMENT_SUCCESS = 'DELETE_TOKENAPPOINTMENT_SUCCESS';
export const DELETE_TOKENAPPOINTMENT_FAILURE = 'DELETE_TOKENAPPOINTMENT_FAILURE';

//delete staff
export const DELETE_STAFF_REQUEST = 'DELETE_STAFF_REQUEST';
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAILURE = 'DELETE_STAFF_FAILURE';

//delete area
export const DELETE_AREA_REQUEST = 'DELETE_AREA_REQUEST';
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS';
export const DELETE_AREA_FAILURE = 'DELETE_AREA_FAILURE';

//delete BILLING
export const DELETE_BILLING_REQUEST = 'DELETE_BILLING_REQUEST';
export const DELETE_BILLING_SUCCESS = 'DELETE_BILLING_SUCCESS';
export const DELETE_BILLING_FAILURE = 'DELETE_BILLING_FAILURE';

//delete clinic
export const DELETE_CLINIC_REQUEST = 'DELETE_CLINIC_REQUEST';
export const DELETE_CLINIC_SUCCESS = 'DELETE_CLINIC_SUCCESS';
export const DELETE_CLINIC_FAILURE = 'DELETE_CLINIC_FAILURE';

//delete tag
export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';


//delete tag
export const DELETE_PATIENTINFO_REQUEST = 'DELETE_PATIENTINFO_REQUEST';
export const DELETE_PATIENTINFO_SUCCESS = 'DELETE_PATIENTINFO_SUCCESS';
export const DELETE_PATIENTINFO_FAILURE = 'DELETE_PATIENTINFO_FAILURE';

//delete tag
export const DELETE_TREATMENT_REQUEST = 'DELETE_TREATMENT_REQUEST';
export const DELETE_TREATMENT_SUCCESS = 'DELETE_TREATMENT_SUCCESS';
export const DELETE_TREATMENT_FAILURE = 'DELETE_TREATMENT_FAILURE';

//delete permission
export const DELETE_PERMISSION_REQUEST = 'DELETE_PERMISSION_REQUEST';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_FAILURE = 'DELETE_PERMISSION_FAILURE';

//delete template
export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';

//delete medicalrecords
export const DELETE_MEDICALRECORD_REQUEST = 'DELETE_MEDICALRECORD_REQUEST';
export const DELETE_MEDICALRECORD_SUCCESS = 'DELETE_MEDICALRECORD_SUCCESS';
export const DELETE_MEDICALRECORD_FAILURE = 'DELETE_MEDICALRECORD_FAILURE';

//delete followup
export const DELETE_FOLLOWUP_REQUEST = 'DELETE_FOLLOWUP_REQUEST';
export const DELETE_FOLLOWUP_SUCCESS = 'DELETE_FOLLOWUP_SUCCESS';
export const DELETE_FOLLOWUP_FAILURE = 'DELETE_FOLLOWUP_FAILURE';

//delete notificationSetting
export const DELETE_NOTIFICATION_SETTING_REQUEST = 'DELETE_NOTIFICATION_SETTING_REQUEST';
export const DELETE_NOTIFICATION_SETTING_SUCCESS = 'DELETE_NOTIFICATION_SETTING_SUCCESS';
export const DELETE_NOTIFICATION_SETTING_FAILURE = 'DELETE_NOTIFICATION_SETTING_FAILURE';


//delete notification
export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

//delete MESSAGE
export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE';

//delete DOCTORPROFILE
export const DELETE_DOCTORPROFILE_REQUEST = 'DELETE_DOCTORPROFILE_REQUEST';
export const DELETE_DOCTORPROFILE_SUCCESS = 'DELETE_DOCTORPROFILE_SUCCESS';
export const DELETE_DOCTORPROFILE_FAILURE = 'DELETE_DOCTORPROFILE_FAILURE';

//delete CLINICPROFILE
export const DELETE_CLINICPROFILE_REQUEST = 'DELETE_CLINICPROFILE_REQUEST';
export const DELETE_CLINICPROFILE_SUCCESS = 'DELETE_CLINICPROFILE_SUCCESS';
export const DELETE_CLINICPROFILE_FAILURE = 'DELETE_CLINICPROFILE_FAILURE';


export const FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_REQUEST = 'FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_REQUEST';
export const FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_SUCCESS = 'FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_SUCCESS';
export const FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_FAILURE = 'FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_FAILURE';

//fetch doctorbyid
export const FETCH_DOCTOR_BY_ID_REQUEST = 'FETCH_DOCTOR_BY_ID_REQUEST';
export const FETCH_DOCTOR_BY_ID_SUCCESS = 'FETCH_DOCTOR_BY_ID_SUCCESS';
export const FETCH_DOCTOR_BY_ID_FAILURE = 'FETCH_DOCTOR_BY_ID_FAILURE';


//fetch patientById
export const FETCH_PATIENT_BY_ID_REQUEST = 'FETCH_PATIENT_BY_ID_REQUEST';
export const FETCH_PATIENT_BY_ID_SUCCESS = 'FETCH_PATIENT_BY_ID_SUCCESS';
export const FETCH_PATIENT_BY_ID_FAILURE = 'FETCH_PATIENT_BY_ID_FAILURE';

//fetch appointmentById
export const FETCH_TOKENAPPOINTMENT_BY_ID_REQUEST = 'FETCH_TOKENAPPOINTMENT_BY_ID_REQUEST';
export const FETCH_TOKENAPPOINTMENT_BY_ID_SUCCESS = 'FETCH_TOKENAPPOINTMENT_BY_ID_SUCCESS';
export const FETCH_TOKENAPPOINTMENT_BY_ID_FAILURE = 'FETCH_TOKENAPPOINTMENT_BY_ID_FAILURE';

//fetch areaById
export const FETCH_AREA_BY_ID_REQUEST = 'FETCH_AREA_BY_ID_REQUEST';
export const FETCH_AREA_BY_ID_SUCCESS = 'FETCH_AREA_BY_ID_SUCCESS';
export const FETCH_AREA_BY_ID_FAILURE = 'FETCH_AREA_BY_ID_FAILURE';

//fetch clinicById
export const FETCH_CLINIC_BY_ID_REQUEST = 'FETCH_CLINIC_BY_ID_REQUEST';
export const FETCH_CLINIC_BY_ID_SUCCESS = 'FETCH_CLINIC_BY_ID_SUCCESS';
export const FETCH_CLINIC_BY_ID_FAILURE = 'FETCH_CLINIC_BY_ID_FAILURE';

//fetch staffById
export const FETCH_STAFF_BY_ID_REQUEST = 'FETCH_STAFF_BY_ID_REQUEST';
export const FETCH_STAFF_BY_ID_SUCCESS = 'FETCH_STAFF_BY_ID_SUCCESS';
export const FETCH_STAFF_BY_ID_FAILURE = 'FETCH_STAFF_BY_ID_FAILURE';

//fetch tagById
export const FETCH_TAG_BY_ID_REQUEST = 'FETCH_TAG_BY_ID_REQUEST';
export const FETCH_TAG_BY_ID_SUCCESS = 'FETCH_TAG_BY_ID_SUCCESS';
export const FETCH_TAG_BY_ID_FAILURE = 'FETCH_TAG_BY_ID_FAILURE';

//fetch tagById
export const FETCH_BILLING_BY_ID_REQUEST = 'FETCH_BILLING_BY_ID_REQUEST';
export const FETCH_BILLING_BY_ID_SUCCESS = 'FETCH_BILLING_BY_ID_SUCCESS';
export const FETCH_BILLING_BY_ID_FAILURE = 'FETCH_BILLING_BY_ID_FAILURE';

//fetch medicalrecordById
export const FETCH_MEDICALRECORD_BY_ID_REQUEST = 'FETCH_MEDICALRECORD_BY_ID_REQUEST';
export const FETCH_MEDICALRECORD_BY_ID_SUCCESS = 'FETCH_MEDICALRECORD_BY_ID_SUCCESS';
export const FETCH_MEDICALRECORD_BY_ID_FAILURE = 'FETCH_MEDICALRECORD_BY_ID_FAILURE';

//fetch PERMISSIONById
export const FETCH_PERMISSION_BY_ID_REQUEST = 'FETCH_PERMISSION_BY_ID_REQUEST';
export const FETCH_PERMISSION_BY_ID_SUCCESS = 'FETCH_PERMISSION_BY_ID_SUCCESS';
export const FETCH_PERMISSION_BY_ID_FAILURE = 'FETCH_PERMISSION_BY_ID_FAILURE';

//fetch tagById
export const FETCH_TEMPLATE_BY_ID_REQUEST = 'FETCH_TEMPLATE_BY_ID_REQUEST';
export const FETCH_TEMPLATE_BY_ID_SUCCESS = 'FETCH_TEMPLATE_BY_ID_SUCCESS';
export const FETCH_TEMPLATE_BY_ID_FAILURE = 'FETCH_TEMPLATE_BY_ID_FAILURE';

//fetch notificationById
export const FETCH_NOTIFICATION_BY_ID_REQUEST = 'FETCH_NOTIFICATION_BY_ID_REQUEST';
export const FETCH_NOTIFICATION_BY_ID_SUCCESS = 'FETCH_NOTIFICATION_BY_ID_SUCCESS';
export const FETCH_NOTIFICATION_BY_ID_FAILURE = 'FETCH_NOTIFICATION_BY_ID_FAILURE';

//fetch notificationSETTINGById
export const FETCH_NOTIFICATIONSETTING_BY_ID_REQUEST = 'FETCH_NOTIFICATIONSETTING_BY_ID_REQUEST';
export const FETCH_NOTIFICATIONSETTING_BY_ID_SUCCESS = 'FETCH_NOTIFICATIONSETTING_BY_ID_SUCCESS';
export const FETCH_NOTIFICATIONSETTING_BY_ID_FAILURE = 'FETCH_NOTIFICATIONSETTING_BY_ID_FAILURE';


//fetch MESSAGEById
export const FETCH_MESSAGE_BY_ID_REQUEST = 'FETCH_MESSAGE_BY_ID_REQUEST';
export const FETCH_MESSAGE_BY_ID_SUCCESS = 'FETCH_MESSAGE_BY_ID_SUCCESS';
export const FETCH_MESSAGE_BY_ID_FAILURE = 'FETCH_MESSAGE_BY_ID_FAILURE';

//fetch TREATMENTById
export const FETCH_TREATMENT_BY_ID_REQUEST = 'FETCH_TREATMENT_BY_ID_REQUEST';
export const FETCH_TREATMENT_BY_ID_SUCCESS = 'FETCH_TREATMENT_BY_ID_SUCCESS';
export const FETCH_TREATMENT_BY_ID_FAILURE = 'FETCH_TREATMENT_BY_ID_FAILURE';

//fetch followupById
export const FETCH_FOLLOWUP_BY_ID_REQUEST = 'FETCH_FOLLOWUP_BY_ID_REQUEST';
export const FETCH_FOLLOWUP_BY_ID_SUCCESS = 'FETCH_FOLLOWUP_BY_ID_SUCCESS';
export const FETCH_FOLLOWUP_BY_ID_FAILURE = 'FETCH_FOLLOWUP_BY_ID_FAILURE';

//fetch MESSAGEById
export const FETCH_PATIENTINFO_BY_ID_REQUEST = 'FETCH_PATIENTINFO_BY_ID_REQUEST';
export const FETCH_PATIENTINFO_BY_ID_SUCCESS = 'FETCH_PATIENTINFO_BY_ID_SUCCESS';
export const FETCH_PATIENTINFO_BY_ID_FAILURE = 'FETCH_PATIENTINFO_BY_ID_FAILURE';

//update

//update doctor
export const UPDATE_DOCTOR_REQUEST = 'UPDATE_DOCTOR_REQUEST';
export const UPDATE_DOCTOR_SUCCESS = 'UPDATE_DOCTOR_SUCCESS';
export const UPDATE_DOCTOR_FAILURE = 'UPDATE_DOCTOR_FAILURE';

//update patient
export const UPDATE_PATIENT_REQUEST = 'UPDATE_PATIENT_REQUEST';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAILURE = 'UPDATE_PATIENT_FAILURE';

//update area
export const UPDATE_AREA_REQUEST = 'UPDATE_AREA_REQUEST';
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS';
export const UPDATE_AREA_FAILURE = 'UPDATE_AREA_FAILURE';

//update clinic
export const UPDATE_CLINIC_REQUEST = 'UPDATE_CLINIC_REQUEST';
export const UPDATE_CLINIC_SUCCESS = 'UPDATE_CLINIC_SUCCESS';
export const UPDATE_CLINIC_FAILURE = 'UPDATE_CLINIC_FAILURE';

//update clinic
export const UPDATE_STAFF_REQUEST = 'UPDATE_STAFF_REQUEST';
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';
export const UPDATE_STAFF_FAILURE = 'UPDATE_STAFF_FAILURE';

//update tag
export const UPDATE_TAG_REQUEST = 'UPDATE_TAG_REQUEST';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';

//update BILLING
export const UPDATE_BILLING_REQUEST = 'UPDATE_BILLING_REQUEST';
export const UPDATE_BILLING_SUCCESS = 'UPDATE_BILLING_SUCCESS';
export const UPDATE_BILLING_FAILURE = 'UPDATE_BILLING_FAILURE';

//update medicalrecord
export const UPDATE_MEDICALRECORD_REQUEST = 'UPDATE_MEDICALRECORD_REQUEST';
export const UPDATE_MEDICALRECORD_SUCCESS = 'UPDATE_MEDICALRECORD_SUCCESS';
export const UPDATE_MEDICALRECORD_FAILURE = 'UPDATE_MEDICALRECORD_FAILURE';

//update permission
export const UPDATE_PERMISSION_REQUEST = 'UPDATE_PERMISSION_REQUEST';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAILURE = 'UPDATE_PERMISSION_FAILURE';

//update template
export const UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAILURE = 'UPDATE_TEMPLATE_FAILURE';

//update NOTIFICATION
export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

//update NOTIFICATIONSETTING
export const UPDATE_NOTIFICATIONSETTING_REQUEST = 'UPDATE_NOTIFICATIONSETTING_REQUEST';
export const UPDATE_NOTIFICATIONSETTING_SUCCESS = 'UPDATE_NOTIFICATIONSETTING_SUCCESS';
export const UPDATE_NOTIFICATIONSETTING_FAILURE = 'UPDATE_NOTIFICATIONSETTING_FAILURE';


//update tokenappointment
export const UPDATE_TOKENAPPOINTMENT_REQUEST = 'UPDATE_TOKENAPPOINTMENT_REQUEST';
export const UPDATE_TOKENAPPOINTMENT_SUCCESS = 'UPDATE_TOKENAPPOINTMENT_SUCCESS';
export const UPDATE_TOKENAPPOINTMENT_FAILURE = 'UPDATE_TOKENAPPOINTMENT_FAILURE';

//update message
export const UPDATE_MESSAGE_REQUEST = 'UPDATE_MESSAGE_REQUEST';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';
export const UPDATE_MESSAGE_FAILURE = 'UPDATE_MESSAGE_FAILURE';

//update TREATMENT
export const UPDATE_TREATMENT_REQUEST = 'UPDATE_TREATMENT_REQUEST';
export const UPDATE_TREATMENT_SUCCESS = 'UPDATE_TREATMENT_SUCCESS';
export const UPDATE_TREATMENT_FAILURE = 'UPDATE_TREATMENT_FAILURE';

//update FOLLOWUP
export const UPDATE_FOLLOWUP_REQUEST = 'UPDATE_FOLLOWUP_REQUEST';
export const UPDATE_FOLLOWUP_SUCCESS = 'UPDATE_FOLLOWUP_SUCCESS';
export const UPDATE_FOLLOWUP_FAILURE = 'UPDATE_FOLLOWUP_FAILURE';

//update FOLLOWUP
export const UPDATE_PATIENTINFO_REQUEST = 'UPDATE_PATIENTINFO_REQUEST';
export const UPDATE_PATIENTINFO_SUCCESS = 'UPDATE_PATIENTINFO_SUCCESS';
export const UPDATE_PATIENTINFO_FAILURE = 'UPDATE_PATIENTINFO_FAILURE';


export const UPDATE_TASK_STATUS_REQUEST = 'UPDATE_TASK_STATUS_REQUEST';
export const UPDATE_TASK_STATUS_SUCCESS = 'UPDATE_TASK_STATUS_SUCCESS';
export const UPDATE_TASK_STATUS_FAILURE = 'UPDATE_TASK_STATUS_FAILURE';