import React, { useState, useEffect, useMemo } from "react";
import { FormBox } from "../doctor/style";
import * as Yup from 'yup';
import { Grid, Typography } from "@mui/material";
import InputComponent from "src/components/shared/Form/Input";
import Select from 'src/components/shared/Form/Select';
import { Button } from "src/components/shared";
import { addFollowupRequest, updateFollowupRequest, fetchDoctornameRequest, fetchPatientnameRequest, fetchClinicnameRequest, fetchTokenAppointmentByPatientIdRequest, fetchFollowupByIdRequest } from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import toast from "src/utils/toast";

function AddEditFollowup() {
    const dispatch = useDispatch();
    const { id } = useParams(); 
    const navigate = useNavigate();
    const doctors = useSelector((state) => state.app.doctors);
    const clinics = useSelector((state) => state.app.clinics || []);
    const patients = useSelector((state) => state.app.patients);
    const [isLoading, setIsLoading] = useState(true);
    const [tokenAppointments, setTokenAppointments] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState('');
    
    const [initialValues, setInitialValues] = useState({
        totalFollowup: '',
        remainingFollowup: '',
        days: '',
        user: '',
        patient: '',
        tokenAppointment: '',
        clinic: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchDoctornameRequest(resolve, reject))),
                    new Promise((resolve, reject) => dispatch(fetchClinicnameRequest(resolve, reject))),
                    new Promise((resolve, reject) => dispatch(fetchPatientnameRequest(resolve, reject)))
                ]);

               
                if (id) {
                    const followup = await new Promise((resolve, reject) => 
                        dispatch(fetchFollowupByIdRequest(id, resolve, reject))
                    );
                    setInitialValues({
                        totalFollowup: followup.totalFollowup || '',
                        remainingFollowup: followup.remainingFollowup || '',
                        days: followup.days || '',
                        user: followup.user || '',
                        patient: followup.patient || '',
                        tokenAppointment: followup.tokenAppointment || '',
                        clinic: followup.clinic || '',
                    });
                }

                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    const handleFetchTokenAppointments = (patientId) => {
        dispatch(fetchTokenAppointmentByPatientIdRequest(patientId, (data) => {
            setTokenAppointments(data);
        }, (error) => {
            console.error('Error:', error);
        }));
    };

    const handlePatientChange = (e) => {
        const patientId = e.target.value;
        setSelectedPatient(patientId);
        handleFetchTokenAppointments(patientId);
    };

    const handleSubmit = async (values, { resetForm }) => {
        const followup = { ...values };

        try {
            if (id) {
                await new Promise((resolve, reject) => dispatch(updateFollowupRequest(id, followup, resolve, reject)));
                toast.success('Followup Updated Successfully');
            } else {
                await new Promise((resolve, reject) => dispatch(addFollowupRequest(followup, resolve, reject)));
                toast.success('Followup Added Successfully');
                resetForm();
            }
            navigate('/followups'); 
        } catch (error) {
            toast.error('Error saving followup');
        }
    };

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({
            label: doctor.name,
            value: doctor.id,
        }))
    ), [doctors]);

    const clinicOptions = useMemo(() => (
        clinics.map((clinic) => ({
            label: clinic.name,
            value: clinic.id,
        }))
    ), [clinics]);

    const patientOptions = useMemo(() => (
        patients.map((patient) => ({
            label: patient.name,
            value: patient.id,
        }))
    ), [patients]);

    const tokenAppointmentOptions = useMemo(() => (
        tokenAppointments.map((appointment) => ({
            label: appointment.id,
            value: appointment.id,
        }))
    ), [tokenAppointments]);

    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                enableReinitialize 
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    totalFollowup: Yup.string().required("Please enter total followup!"),
                    remainingFollowup: Yup.string().required('Please enter the remaining followup!'),
                    days: Yup.string().required('Please enter the days!'),
                    patient: Yup.string().required('Please select the patient!'),
                    tokenAppointment: Yup.string().required('Please select the token appointment!'),
                    clinic: Yup.string().required('Please select the clinic!'),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue, isValid }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Typography variant="h4" color='#393A96' fontWeight='bold'>
                                {id ? 'Edit Followup' : 'Add Followup'}
                            </Typography>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Total Follow-Up
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="totalFollowup"
                                    value={values.totalFollowup}
                                    onChange={handleChange}
                                    error={touched.totalFollowup && Boolean(errors.totalFollowup)}
                                    helperText={touched.totalFollowup && errors.totalFollowup}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Remaining Follow-Up
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="remainingFollowup"
                                    value={values.remainingFollowup}
                                    onChange={handleChange}
                                    error={touched.remainingFollowup && Boolean(errors.remainingFollowup)}
                                    helperText={touched.remainingFollowup && errors.remainingFollowup}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Days
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="days"
                                    value={values.days}
                                    onChange={handleChange}
                                    error={touched.days && Boolean(errors.days)}
                                    helperText={touched.days && errors.days}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='user'
                                    value={values.user}
                                    onChange={(e) => setFieldValue('user', e.target.value)}
                                    options={doctorOptions}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Clinic
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='clinic'
                                    value={values.clinic}
                                    onChange={(e) => setFieldValue('clinic', e.target.value)}
                                    options={clinicOptions}
                                    placeholder="Select a clinic"
                                    error={touched.clinic && Boolean(errors.clinic)}
                                    helperText={touched.clinic && errors.clinic}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Patient
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='patient'
                                    value={values.patient}
                                    onChange={(e) => {
                                        handlePatientChange(e);
                                        setFieldValue('patient', e.target.value);
                                    }}
                                    options={patientOptions}
                                    placeholder="Select a patient"
                                    error={touched.patient && Boolean(errors.patient)}
                                    helperText={touched.patient && errors.patient}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Token Appointment
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='tokenAppointment'
                                    value={values.tokenAppointment}
                                    onChange={(e) => setFieldValue('tokenAppointment', e.target.value)}
                                    options={tokenAppointmentOptions}
                                    placeholder="Select a token appointment"
                                    error={touched.tokenAppointment && Boolean(errors.tokenAppointment)}
                                    helperText={touched.tokenAppointment && errors.tokenAppointment}
                                />
                            </Grid>

                            <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!isValid}
                                >
                                    {id ? 'Update Followup' : 'Add Followup'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </FormBox>
    );
}

export default AddEditFollowup;
