import * as appTypes from './appTypes';

export const setAppLoading = (loading) => ({
  type: appTypes.SET_APP_LOADING,
  loading,
});

export const searchRequest = (input) => ({
  type: appTypes.SEARCH_REQUEST,
  payload: input,
});

export const searchSuccess = (data) => ({
  type: appTypes.SEARCH_SUCCESS,
  payload: data,
});

export const searchFailure = (error) => ({
  type: appTypes.SEARCH_FAILURE,
  payload: error,
});

export const addDoctorRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_DOCTOR_REQUEST,
  payload: {data, resolve, reject },
});

export const addDoctorSuccess = (data) => ({
  type: appTypes.ADD_DOCTOR_SUCCESS,
  payload: { data },
});

export const addDoctorFailure = (error) => ({
  type: appTypes.ADD_DOCTOR_FAILURE,
  payload: { error },
});

//add patient

export const addPatientRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_PATIENT_REQUEST,
  payload: { data, resolve, reject },
});
export const addPatientSuccess = (data) => ({
  type: appTypes.ADD_PATIENT_SUCCESS,
  payload: { data },
});
export const addPatientFailure = (error) => ({
  type: appTypes.ADD_PATIENT_FAILURE,
  payload: { error },
});

//ADD CLINIC
export const addClinicRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_CLINIC_REQUEST,
  payload: { data, resolve, reject },
});

export const addClinicSuccess = (data) => ({
  type: appTypes.ADD_CLINIC_SUCCESS,
  data,
});

export const addClinicFailure = (error) => ({
  type: appTypes.ADD_CLINIC_FAILURE,
  payload: { error },
});

//add tokenappointment
export const addTokenAppointmentRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_TOKENAPPOINTMENT_REQUEST,
  payload: { data, resolve, reject },
});

export const addTokenAppointmentSuccess = (data) => ({
  type: appTypes.ADD_TOKENAPPOINTMENT_SUCCESS,
  payload: { data },
});

export const addTokenAppointmentFailure = (error) => ({
  type: appTypes.ADD_TOKENAPPOINTMENT_FAILURE,
  payload: { error },
});

//add task
export const addTaskRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_TASK_REQUEST,
  payload: { data, resolve, reject },
});

export const addTaskSuccess = (data) => ({
  type: appTypes.ADD_TASK_SUCCESS,
  data,
});

export const addTaskFailure = (error) => ({
  type: appTypes.ADD_TASK_FAILURE,
  payload: { error },
});

//add Staff
export const addStaffRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_STAFF_REQUEST,
  payload: { data, resolve, reject },
});

export const addStaffSuccess = (data) => ({
  type: appTypes.ADD_STAFF_SUCCESS,
  payload: { data },
});

export const addStaffFailure = (error) => ({
  type: appTypes.ADD_STAFF_FAILURE,
  payload: { error },
});

//add area
export const addAreaRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_AREA_REQUEST,
  payload: { data, resolve, reject },
});

export const addAreaSuccess = (data) => ({
  type: appTypes.ADD_AREA_SUCCESS,
  payload: { data },
});

export const addAreaFailure = (error) => ({
  type: appTypes.ADD_AREA_FAILURE,
  payload: { error },
});

//add tag
export const addTagRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_TAG_REQUEST,
  payload: { data, resolve, reject },
});

export const addTagSuccess = (data) => ({
  type: appTypes.ADD_TAG_SUCCESS,
  payload: { data },
});

export const addTagFailure = (error) => ({
  type: appTypes.ADD_TAG_FAILURE,
  payload: { error },
}); 

//add tag
export const addBillingRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_BILLING_REQUEST,
  payload: { data, resolve, reject },
});

export const addBillingSuccess = (data) => ({
  type: appTypes.ADD_BILLING_SUCCESS,
  payload: { data },
});

export const addBillingFailure = (error) => ({
  type: appTypes.ADD_BILLING_FAILURE,
  payload: { error },
}); 

//add permission
export const addPermissionRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_PERMISSION_REQUEST,
  payload: { data, resolve, reject },
});

export const addPermissionSuccess = (data) => ({
  type: appTypes.ADD_PERMISSION_SUCCESS,
  payload: { data },
});

export const addPermissionFailure = (error) => ({
  type: appTypes.ADD_PERMISSION_FAILURE,
  payload: { error },
});

//add template
export const addTemplateRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_TEMPLATE_REQUEST,
  payload: { data, resolve, reject },
});

export const addTemplateSuccess = (data) => ({
  type: appTypes.ADD_TEMPLATE_SUCCESS,
  payload: { data },
});

export const addTemplateFailure = (error) => ({
  type: appTypes.ADD_TEMPLATE_FAILURE,
  payload: { error },
});

//add treatment
export const addTreatmentRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_TREATMENT_REQUEST,
  payload: { data, resolve, reject },
});

export const addTreatmentSuccess = (data) => ({
  type: appTypes.ADD_TREATMENT_SUCCESS,
  payload: { data },
});

export const addTreatmentFailure = (error) => ({
  type: appTypes.ADD_TREATMENT_FAILURE,
  payload: { error },
});

//add medicalrecord
export const addMedicalRecordRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_MEDICALRECORD_REQUEST,
  payload: { data, resolve, reject },
});

export const addMedicalRecordSuccess = (data) => ({
  type: appTypes.ADD_MEDICALRECORD_SUCCESS,
  payload: { data },
});

export const addMedicalRecordFailure = (error) => ({
  type: appTypes.ADD_MEDICALRECORD_FAILURE,
  payload: { error },
});

//add followup
export const addFollowupRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_FOLLOWUP_REQUEST,
  payload: { data, resolve, reject },
});

export const addFollowupSuccess = (data) => ({
  type: appTypes.ADD_FOLLOWUP_SUCCESS,
  payload: { data },
});

export const addFollowupFailure = (error) => ({
  type: appTypes.ADD_FOLLOWUP_FAILURE,
  payload: { error },
});

//add notificationsetting
export const addNotificationSettingRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_NOTIFICATIONSETTING_REQUEST,
  payload: { data, resolve, reject },
});

export const addNotificationSettingSuccess = (data) => ({
  type: appTypes.ADD_NOTIFICATIONSETTING_SUCCESS,
  payload: { data },
});

export const addNotificationSettingFailure = (error) => ({
  type: appTypes.ADD_NOTIFICATIONSETTING_FAILURE,
  payload: { error },
});

//add notification
export const addNotificationRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_NOTIFICATION_REQUEST,
  payload: { data, resolve, reject },
});

export const addNotificationSuccess = (data) => ({
  type: appTypes.ADD_NOTIFICATION_SUCCESS,
  payload: { data },
});

export const addNotificationFailure = (error) => ({
  type: appTypes.ADD_NOTIFICATION_FAILURE,
  payload: { error },
});

//add Message
export const addMessageRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_MESSAGE_REQUEST,
  payload: { data, resolve, reject },
});

export const addMessageSuccess = (data) => ({
  type: appTypes.ADD_MESSAGE_SUCCESS,
  payload: { data },
});

export const addMessageFailure = (error) => ({
  type: appTypes.ADD_MESSAGE_FAILURE,
  payload: { error },
});

//add patientinfo
export const addPatientInfoRequest = (token, resolve, reject) => ({
  type: appTypes.ADD_PATIENTINFO_REQUEST,
  payload: { token, resolve, reject },
});

export const addPatientInfoSuccess = (data) => ({
  type: appTypes.ADD_PATIENTINFO_SUCCESS,
  payload: { data },
});

export const addPatientInfoFailure = (error) => ({
  type: appTypes.ADD_PATIENTINFO_FAILURE,
  payload: { error },
});

//add doctorprofile
export const addDoctorProfileRequest = (data, resolve, reject) => ({
  type: appTypes.ADD_DOCTORPROFILE_REQUEST,
  payload: { data, resolve, reject },
});

export const addDoctorProfileSuccess = (data) => ({
  type: appTypes.ADD_DOCTORPROFILE_SUCCESS,
  payload: { data },
});

export const addDoctorProfileFailure = (error) => ({
  type: appTypes.ADD_DOCTORPROFILE_FAILURE,
  payload: { error },
});


//fetch  DOCTORname  
export const fetchDoctornameRequest = (resolve, reject) => ({
  type: appTypes.FETCH_DOCTOR_NAMES_REQUEST,
  payload: { resolve, reject },
});

export const fetchDoctornameSuccess = (data) => ({
  type: appTypes.FETCH_DOCTOR_NAMES_SUCCESS,
  data,
});

export const fetchDoctornameFailure = (error) => ({
  type: appTypes.FETCH_DOCTOR_NAMES_FAILURE,
  payload: { error },
});

//GET CLINICNAME
export const fetchClinicnameRequest = (resolve, reject) => ({
  type: appTypes.FETCH_CLINIC_NAMES_REQUEST,
  payload: { resolve, reject },
});

export const fetchClinicnameSuccess = (data) => ({
  type: appTypes.FETCH_CLINIC_NAMES_SUCCESS,
  data,
});

export const fetchClinicnameFailure = (error) => ({
  type: appTypes.FETCH_CLINIC_NAMES_FAILURE,
  payload: { error },
});

//get patientname
export const fetchPatientnameRequest = (resolve, reject) => ({
  type: appTypes.FETCH_PATIENT_NAMES_REQUEST,
  payload: { resolve, reject },
});

export const fetchPatientnameSuccess = (data) => ({
  type: appTypes.FETCH_PATIENT_NAMES_SUCCESS,
  data,
});

export const fetchPatientnameFailure = (error) => ({
  type: appTypes.FETCH_PATIENT_NAMES_FAILURE,
  payload: { error },
});



//get doctor

export const fetchDoctorsRequest = (token,page,perpage,resolve, reject) => ({
  type: appTypes.FETCH_DOCTORS_REQUEST,
  payload: {token,page,perpage, resolve, reject },
});


export const fetchDoctorsSuccess = (data) => ({
  type: appTypes.FETCH_DOCTORS_SUCCESS,
  payload: { data },
});

export const fetchDoctorsFailure = (error) => ({
  type: appTypes.FETCH_DOCTORS_FAILURE,
  payload: { error },
});


//getPatient
export const fetchPatientsRequest = (token, page, perPage, resolve, reject) => ({
  type: appTypes.FETCH_PATIENTS_REQUEST,
  payload: { token, page, perPage, resolve, reject },
});


export const fetchPatientsSuccess = (data) => ({
  type: appTypes.FETCH_PATIENTS_SUCCESS,
  payload: { data },
});

export const fetchPatientsFailure = (error) => ({
  type: appTypes.FETCH_PATIENTS_FAILURE,
  payload: { error },
});

//get Saff
export const fetchStaffsRequest = (token ,page,perpage,resolve ,reject) => ({
  type: appTypes.FETCH_STAFFS_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchStaffsSuccess = (data) => ({
  type: appTypes.FETCH_STAFFS_SUCCESS,
  payload: { data },
});

export const fetchStaffsFailure = (error) => ({
  type: appTypes.FETCH_STAFFS_FAILURE,
  payload: { error } ,
});

//get Task
export const fetchTasksRequest = (token , page, perPage,resolve ,reject) => ({
  type: appTypes.FETCH_TASKS_REQUEST,
  payload: { token, page, perPage, resolve, reject },
});

export const fetchTasksSuccess = (data,meta) => ({
  type: appTypes.FETCH_TASKS_SUCCESS,
  payload: { data,meta },
});

export const fetchTasksFailure = (error) => ({
  type: appTypes.FETCH_TASKS_FAILURE,
  payload: { error } ,
});

//get clinics
export const fetchClinicsRequest = (token,page,perpage ,resolve ,reject) => ({
  type: appTypes.FETCH_CLINICS_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchClinicsSuccess = (data) => ({
  type: appTypes.FETCH_CLINICS_SUCCESS,
  payload: { data },
});

export const fetchClinicsFailure = (error) => ({
  type: appTypes.FETCH_CLINICS_FAILURE,
  payload: { error } ,
});

//get Appointment
export const fetchTokenAppointmentRequest = (token ,page,perpage,resolve ,reject) => ({
  type: appTypes.FETCH_TOKENAPPOINTMENT_REQUEST,
  payload:{ token ,page,perpage,resolve ,reject}
});

export const fetchTokenAppointmentSuccess = (data) => ({
  type: appTypes.FETCH_TOKENAPPOINTMENT_SUCCESS,
  data,
});

export const fetchTokenAppointmentFailure = (error) => ({
  type: appTypes.FETCH_TOKENAPPOINTMENT_FAILURE,
  payload: { error },
});

//get billings
export const fetchBillingsRequest = (token,page,perpage,resolve ,reject) => ({
  type: appTypes.FETCH_BILLINGS_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchBillingsSuccess = (data) => ({
  type: appTypes.FETCH_BILLINGS_SUCCESS,
  payload: { data },
});

export const fetchBillingsFailure = (error) => ({
  type: appTypes.FETCH_BILLINGS_FAILURE,
  payload: { error } ,
});

//get areas
export const fetchAreasRequest = (token ,page,perpage,resolve ,reject) => ({
  type: appTypes.FETCH_AREAS_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchAreasSuccess = (data) => ({
  type: appTypes.FETCH_AREAS_SUCCESS,
  payload: { data },
});

export const fetchAreasFailure = (error) => ({
  type: appTypes.FETCH_AREAS_FAILURE,
  payload: { error } ,
});

//get tags
export const fetchTagsRequest = (token,page,perpage ,resolve ,reject) => ({
  type: appTypes.FETCH_TAGS_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchTagsSuccess = (data) => ({
  type: appTypes.FETCH_TAGS_SUCCESS,
  payload: { data },
});

export const fetchTagsFailure = (error) => ({
  type: appTypes.FETCH_TAGS_FAILURE,
  payload: { error } ,
});

//get patientinfo
export const fetchPatientInfosRequest = (token,page,perpage ,resolve ,reject) => ({
  type: appTypes.FETCH_PATIENTINFOS_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchPatientInfosSuccess = (data) => ({
  type: appTypes.FETCH_PATIENTINFOS_SUCCESS,
  payload: { data },
});

export const fetchPatientInfosFailure = (error) => ({
  type: appTypes.FETCH_PATIENTINFOS_FAILURE,
  payload: { error } ,
});


//get treatment
export const fetchTreatmentsRequest = (token,page,perpage ,resolve ,reject) => ({
  type: appTypes.FETCH_TREATMENTS_REQUEST,
  payload: { token,page,perpage,resolve, reject },
});

export const fetchTreatmentsSuccess = (data) => ({
  type: appTypes.FETCH_TREATMENTS_SUCCESS,
  payload: { data },
});

export const fetchTreatmentsFailure = (error) => ({
  type: appTypes.FETCH_TREATMENTS_FAILURE,
  payload: { error } ,
});

//get permissions
export const fetchPermissionsRequest = (token,page,perpage,resolve ,reject) => ({
  type: appTypes.FETCH_PERMISSIONS_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchPermissionsSuccess = (data) => ({
  type: appTypes.FETCH_PERMISSIONS_SUCCESS,
  payload: { data },
});

export const fetchPermissionsFailure = (error) => ({
  type: appTypes.FETCH_PERMISSIONS_FAILURE,
  payload: { error } ,
});

//get templates
export const fetchTemplatesRequest = (token,page,perpage ,resolve ,reject) => ({
  type: appTypes.FETCH_TEMPLATES_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchTemplatesSuccess = (data) => ({
  type: appTypes.FETCH_TEMPLATES_SUCCESS,
  payload: { data },
});

export const fetchTemplatesFailure = (error) => ({
  type: appTypes.FETCH_TEMPLATES_FAILURE,
  payload: { error } ,
});

//GET MEDICALRECORDS
export const fetchMedicalRecordsRequest = (token,page,perpage, resolve, reject) => ({
  type: appTypes.FETCH_MEDICALRECORDS_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchMedicalRecordsSuccess = (data) => ({
  type: appTypes.FETCH_MEDICALRECORDS_SUCCESS,
  payload: { data },
});

export const fetchMedicalRecordsFailure = (error) => ({
  type: appTypes.FETCH_MEDICALRECORDS_FAILURE,
  payload: { error },
});

//GET FOLLOWUPS
export const fetchFollowupsRequest = (token,page,perpage, resolve, reject) => ({
  type: appTypes.FETCH_FOLLOWUPS_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchFollowupsSuccess = (data) => ({
  type: appTypes.FETCH_FOLLOWUPS_SUCCESS,
  payload: { data },
});

export const fetchFollowupsFailure = (error) => ({
  type: appTypes.FETCH_FOLLOWUPS_FAILURE,
  payload: { error },
});

//get notificationSetting
export const fetchNotificationSettingRequest = (token,page,perpage, resolve, reject) => ({
  type: appTypes.FETCH_NOTIFICATION_SETTING_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchNotificationSettingSuccess = (data) => ({
  type: appTypes.FETCH_NOTIFICATION_SETTING_SUCCESS,
  payload: { data },
});

export const fetchNotificationSettingFailure = (error) => ({
  type: appTypes.FETCH_NOTIFICATION_SETTING_FAILURE,
  payload: { error },
});

//get messages
export const fetchMessagesRequest = (token,page,perpage, resolve, reject) => ({
  type: appTypes.FETCH_MESSAGES_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchMessagesSuccess = (data) => ({
  type: appTypes.FETCH_MESSAGES_SUCCESS,
  payload: { data },
});

export const fetchMessagesFailure = (error) => ({
  type: appTypes.FETCH_MESSAGES_FAILURE,
  payload: { error },
});

//get notification
export const fetchNotificationsRequest = (token,page,perpage, resolve, reject) => ({
  type: appTypes.FETCH_NOTIFICATIONS_REQUEST,
  payload: { token,page,perpage, resolve, reject },
});

export const fetchNotificationsSuccess = (data) => ({
  type: appTypes.FETCH_NOTIFICATIONS_SUCCESS,
  payload: { data },
});

export const fetchNotificationsFailure = (error) => ({
  type: appTypes.FETCH_NOTIFICATIONS_FAILURE,
  payload: { error },
});

//get doctor profiles
export const fetchDoctorProfilesRequest = (token, page, perpage, resolve, reject) => ({
  type: appTypes.FETCH_DOCTORPROFILES_REQUEST,
  payload: { token, page, perpage, resolve, reject },
});

export const fetchDoctorProfilesSuccess = (data) => ({
  type: appTypes.FETCH_DOCTORPROFILES_SUCCESS,
  payload: { data },
});

export const fetchDoctorProfilesFailure = (error) => ({
  type: appTypes.FETCH_DOCTORPROFILES_FAILURE,
  payload: { error },
});

//get clinic profiles
export const fetchClinicProfilesRequest = (token, page, perpage, resolve, reject) => ({
  type: appTypes.FETCH_CLINICPROFILES_REQUEST,
  payload: { token, page, perpage, resolve, reject },
});

export const fetchClinicProfilesSuccess = (data) => ({
  type: appTypes.FETCH_CLINICPROFILES_SUCCESS,
  payload: { data },
});

export const fetchClinicProfilesFailure = (error) => ({
  type: appTypes.FETCH_CLINICPROFILES_FAILURE,
  payload: { error },
});

//delete doctor

export const deleteDoctorRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_DOCTOR_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteDoctorSuccess = (data) => ({
  type: appTypes.DELETE_DOCTOR_SUCCESS,
  payload: { data },
});

export const deleteDoctorFailure = (error) => ({
  type: appTypes.DELETE_DOCTOR_FAILURE,
  payload: { error },
});

//delete patient

export const deletePatientRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_PATIENT_REQUEST,
  payload: { id, resolve, reject },
});

export const deletePatientSuccess = (data) => ({
  type: appTypes.DELETE_PATIENT_SUCCESS,
  payload: { data },
});

export const deletePatientFailure = (error) => ({
  type: appTypes.DELETE_PATIENT_FAILURE,
  payload: { error },
});

//delete tokenappointment

export const deleteTokenAppointmentRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_TOKENAPPOINTMENT_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteTokenAppointmentSuccess = (data) => ({
  type: appTypes.DELETE_TOKENAPPOINTMENT_SUCCESS,
  payload: { data },
});

export const deleteTokenAppointmentFailure = (error) => ({
  type: appTypes.DELETE_TOKENAPPOINTMENT_FAILURE,
  payload: { error },
});

//delete staff

export const deleteStaffRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_STAFF_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteStaffSuccess = (data) => ({
  type: appTypes.DELETE_STAFF_SUCCESS,
  payload: { data },
});

export const deleteStaffFailure = (error) => ({
  type: appTypes.DELETE_STAFF_FAILURE,
  payload: { error },
});

//delete area

export const deleteAreaRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_AREA_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteAreaSuccess = (data) => ({
  type: appTypes.DELETE_AREA_SUCCESS,
  payload: { data },
});

export const deleteAreaFailure = (error) => ({
  type: appTypes.DELETE_AREA_FAILURE,
  payload: { error },
});

//delete billing

export const deleteBillingRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_BILLING_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteBillingSuccess = (data) => ({
  type: appTypes.DELETE_BILLING_SUCCESS,
  payload: { data },
});

export const deleteBillingFailure = (error) => ({
  type: appTypes.DELETE_BILLING_FAILURE,
  payload: { error },
});

// delete clinic

export const deleteClinicRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_CLINIC_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteClinicSuccess = (data) => ({
  type: appTypes.DELETE_CLINIC_SUCCESS,
  payload: { data },
});

export const deleteClinicFailure = (error) => ({
  type: appTypes.DELETE_CLINIC_FAILURE,
  payload: { error },
});

//delete tag

export const deleteTagRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_TAG_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteTagSuccess = (data) => ({
  type: appTypes.DELETE_TAG_SUCCESS,
  payload: { data },
});

export const deleteTagFailure = (error) => ({
  type: appTypes.DELETE_TAG_FAILURE,
  payload: { error },
});

//delete PatientInfo

export const deletePatientInfoRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_PATIENTINFO_REQUEST,
  payload: { id, resolve, reject },
});

export const deletePatientInfoSuccess = (data) => ({
  type: appTypes.DELETE_PATIENTINFO_SUCCESS,
  payload: { data },
});

export const deletePatientInfoFailure = (error) => ({
  type: appTypes.DELETE_PATIENTINFO_FAILURE,
  payload: { error },
});

//delete treatment

export const deleteTreatmentRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_TREATMENT_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteTreatmentSuccess = (data) => ({
  type: appTypes.DELETE_TREATMENT_SUCCESS,
  payload: { data },
});

export const deleteTreatmentFailure = (error) => ({
  type: appTypes.DELETE_TREATMENT_FAILURE,
  payload: { error },
});

//delete permission

export const deletePermissionRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_PERMISSION_REQUEST,
  payload: { id, resolve, reject },
});

export const deletePermissionSuccess = (data) => ({
  type: appTypes.DELETE_PERMISSION_SUCCESS,
  payload: { data },
});

export const deletePermissionFailure = (error) => ({
  type: appTypes.DELETE_PERMISSION_FAILURE,
  payload: { error },
});

//delete template

export const deleteTemplateRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_TEMPLATE_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteTemplateSuccess = (data) => ({
  type: appTypes.DELETE_TEMPLATE_SUCCESS,
  payload: { data },
});

export const deleteTemplateFailure = (error) => ({
  type: appTypes.DELETE_TEMPLATE_FAILURE,
  payload: { error },
});

//delete medicalrecords

export const deleteMedicalRecordRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_MEDICALRECORD_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteMedicalRecordSuccess = (data) => ({
  type: appTypes.DELETE_MEDICALRECORD_SUCCESS,
  payload: { data },
});

export const deleteMedicalRecordFailure = (error) => ({
  type: appTypes.DELETE_MEDICALRECORD_FAILURE,
  payload: { error },
});

//delete followup

export const deleteFollowupRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_FOLLOWUP_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteFollowupSuccess = (data) => ({
  type: appTypes.DELETE_FOLLOWUP_SUCCESS,
  payload: { data },
});

export const deleteFollowupFailure = (error) => ({
  type: appTypes.DELETE_FOLLOWUP_FAILURE,
  payload: { error },
});

//delete notificationsetting

export const deleteNotificationSettingRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_NOTIFICATION_SETTING_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteNotificationSettingSuccess = (data) => ({
  type: appTypes.DELETE_NOTIFICATION_SETTING_SUCCESS,
  payload: { data },
});

export const deleteNotificationSettingFailure = (error) => ({
  type: appTypes.DELETE_NOTIFICATION_SETTING_FAILURE,
  payload: { error },
});

//delete notification

export const deleteNotificationRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_NOTIFICATION_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteNotificationSuccess = (data) => ({
  type: appTypes.DELETE_NOTIFICATION_SUCCESS,
  payload: { data },
});

export const deleteNotificationFailure = (error) => ({
  type: appTypes.DELETE_NOTIFICATION_FAILURE,
  payload: { error },
});

//delete Message

export const deleteMessageRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_MESSAGE_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteMessageSuccess = (data) => ({
  type: appTypes.DELETE_MESSAGE_SUCCESS,
  payload: { data },
});

export const deleteMessageFailure = (error) => ({
  type: appTypes.DELETE_MESSAGE_FAILURE,
  payload: { error },
});

//delete DOCTORPROFILE

export const deleteDoctorProfileRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_DOCTORPROFILE_REQUEST,
  payload: { id, resolve, reject },
});

export const deleteDoctorProfileSuccess = (data) => ({
  type: appTypes.DELETE_DOCTORPROFILE_SUCCESS,
  payload: { data },
});

export const deleteDoctorProfileFailure = (error) => ({
  type: appTypes.DELETE_DOCTORPROFILE_FAILURE,
  payload: { error },
});

//delete ClinicPROFILE

export const deleteClinicProfileRequest = (clinicId, resolve, reject) => ({
  type: appTypes.DELETE_CLINICPROFILE_REQUEST,
  payload: { clinicId, resolve, reject },
});

export const deleteClinicProfileSuccess = (data) => ({
  type: appTypes.DELETE_CLINICPROFILE_SUCCESS,
  payload: { data },
});

export const deleteClinicProfileFailure = (error) => ({
  type: appTypes.DELETE_CLINICPROFILE_FAILURE,
  payload: { error },
});

export const fetchTokenAppointmentByPatientIdRequest = (patientId, resolve, reject) => ({
  type: appTypes.FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_REQUEST,
  payload: { patientId, resolve, reject },
});

export const fetchTokenAppointmentByPatientIdSuccess = (tokenAppointments) => ({
  type: appTypes.FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_SUCCESS,
  payload: tokenAppointments,
});

export const fetchTokenAppointmentByPatientIdFailure = (error) => ({
  type: appTypes.FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_FAILURE,
  payload: error,
});



//fetch doctorById

export const fetchDoctorByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_DOCTOR_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchDoctorByIdSuccess = (data) => ({
  type: appTypes.FETCH_DOCTOR_BY_ID_SUCCESS,
  payload: data,
});

export const fetchDoctorByIdFailure = (error) => ({
  type: appTypes.FETCH_DOCTOR_BY_ID_FAILURE,
  payload: error,
});

//fetch patientById

export const fetchPatientByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_PATIENT_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchPatientByIdSuccess = (data) => ({
  type: appTypes.FETCH_PATIENT_BY_ID_SUCCESS,
  payload: data,
});

export const fetchPatientByIdFailure = (error) => ({
  type: appTypes.FETCH_PATIENT_BY_ID_FAILURE,
  payload: error,
});

//fetch AREAById

export const fetchAreaByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_AREA_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchAreaByIdSuccess = (data) => ({
  type: appTypes.FETCH_AREA_BY_ID_SUCCESS,
  payload: data,
});

export const fetchAreaByIdFailure = (error) => ({
  type: appTypes.FETCH_AREA_BY_ID_FAILURE,
  payload: error,
});

//fetch clinicById

export const fetchClinicByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_CLINIC_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchClinicByIdSuccess = (data) => ({
  type: appTypes.FETCH_CLINIC_BY_ID_SUCCESS,
  payload: data,
});

export const fetchClinicByIdFailure = (error) => ({
  type: appTypes.FETCH_CLINIC_BY_ID_FAILURE,
  payload: error,
});

//fetch staffById

export const fetchStaffByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_STAFF_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchStaffByIdSuccess = (data) => ({
  type: appTypes.FETCH_STAFF_BY_ID_SUCCESS,
  payload: data,
});

export const fetchStaffByIdFailure = (error) => ({
  type: appTypes.FETCH_STAFF_BY_ID_FAILURE,
  payload: error,
});

//fetch TAGById

export const fetchTagByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_TAG_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchTagByIdSuccess = (data) => ({
  type: appTypes.FETCH_TAG_BY_ID_SUCCESS,
  payload: data,
});

export const fetchTagByIdFailure = (error) => ({
  type: appTypes.FETCH_TAG_BY_ID_FAILURE,
  payload: error,
});

//fetch TAGById

export const fetchBillingByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_BILLING_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchBillingByIdSuccess = (data) => ({
  type: appTypes.FETCH_BILLING_BY_ID_SUCCESS,
  payload: data,
});

export const fetchBillingByIdFailure = (error) => ({
  type: appTypes.FETCH_BILLING_BY_ID_FAILURE,
  payload: error,
});

//fetch TAGById

export const fetchMedicalRecordByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_MEDICALRECORD_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchMedicalRecordByIdSuccess = (data) => ({
  type: appTypes.FETCH_MEDICALRECORD_BY_ID_SUCCESS,
  payload: data,
});

export const fetchMedicalRecordByIdFailure = (error) => ({
  type: appTypes.FETCH_MEDICALRECORD_BY_ID_FAILURE,
  payload: error,
});

//fetch PermissionById

export const fetchPermissionByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_PERMISSION_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchPermissionByIdSuccess = (data) => ({
  type: appTypes.FETCH_PERMISSION_BY_ID_SUCCESS,
  payload: data,
});

export const fetchPermissionByIdFailure = (error) => ({
  type: appTypes.FETCH_PERMISSION_BY_ID_FAILURE,
  payload: error,
});

//fetch TemplateById

export const fetchTemplateByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_TEMPLATE_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchTemplateByIdSuccess = (data) => ({
  type: appTypes.FETCH_TEMPLATE_BY_ID_SUCCESS,
  payload: data,
});

export const fetchTemplateByIdFailure = (error) => ({
  type: appTypes.FETCH_TEMPLATE_BY_ID_FAILURE,
  payload: error,
});

//fetch notificationById

export const fetchNotificationByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_NOTIFICATION_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchNotificationByIdSuccess = (data) => ({
  type: appTypes.FETCH_NOTIFICATION_BY_ID_SUCCESS,
  payload: data,
});

export const fetchNotificationByIdFailure = (error) => ({
  type: appTypes.FETCH_NOTIFICATION_BY_ID_FAILURE,
  payload: error,
});

//fetch notificationSettingById

export const fetchNotificationSettingByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_NOTIFICATIONSETTING_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchNotificationSettingByIdSuccess = (data) => ({
  type: appTypes.FETCH_NOTIFICATIONSETTING_BY_ID_SUCCESS,
  payload: data,
});

export const fetchNotificationSettingByIdFailure = (error) => ({
  type: appTypes.FETCH_NOTIFICATIONSETTING_BY_ID_FAILURE,
  payload: error,
});


//fetch AppointmentById

export const fetchTokenAppointmentByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_TOKENAPPOINTMENT_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchTokenAppointmentByIdSuccess = (data) => ({
  type: appTypes.FETCH_TOKENAPPOINTMENT_BY_ID_SUCCESS,
  payload: data,
});

export const fetchTokenAppointmentByIdFailure = (error) => ({
  type: appTypes.FETCH_TOKENAPPOINTMENT_BY_ID_FAILURE,
  payload: error,
});

//fetch messageById

export const fetchMessageByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_MESSAGE_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchMessageByIdSuccess = (data) => ({
  type: appTypes.FETCH_MESSAGE_BY_ID_SUCCESS,
  payload: data,
});

export const fetchMessageByIdFailure = (error) => ({
  type: appTypes.FETCH_MESSAGE_BY_ID_FAILURE,
  payload: error,
});

//fetch TREATMENTById

export const fetchTreatmentByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_TREATMENT_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchTreatmentByIdSuccess = (data) => ({
  type: appTypes.FETCH_TREATMENT_BY_ID_SUCCESS,
  payload: data,
});

export const fetchTreatmentByIdFailure = (error) => ({
  type: appTypes.FETCH_TREATMENT_BY_ID_FAILURE,
  payload: error,
});

//fetch followupById

export const fetchFollowupByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_FOLLOWUP_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchFollowupByIdSuccess = (data) => ({
  type: appTypes.FETCH_FOLLOWUP_BY_ID_SUCCESS,
  payload: data,
});

export const fetchFollowupByIdFailure = (error) => ({
  type: appTypes.FETCH_FOLLOWUP_BY_ID_FAILURE,
  payload: error,
});


//fetch messageById

export const fetchPatientInfoByIdRequest = (id, resolve, reject) => ({
  type: appTypes.FETCH_PATIENTINFO_BY_ID_REQUEST,
  payload: { id, resolve, reject },
});


export const fetchPatientInfoByIdSuccess = (data) => ({
  type: appTypes.FETCH_PATIENTINFO_BY_ID_SUCCESS,
  payload: data,
});

export const fetchPatientInfoByIdFailure = (error) => ({
  type: appTypes.FETCH_PATIENTINFO_BY_ID_FAILURE,
  payload: error,
});


//update

//update doctor

export const updateDoctorRequest = (doctorId, doctorData) => ({
  type: appTypes.UPDATE_DOCTOR_REQUEST,
  payload: {doctorId, doctorData},
});

export const updateDoctorSuccess = (data) => ({
  type: appTypes.UPDATE_DOCTOR_SUCCESS,
  payload: data,
});

export const updateDoctorFailure = (error) => ({
  type: appTypes.UPDATE_DOCTOR_FAILURE,
  payload: error,
});

//update patient

export const updatePatientRequest = (patientId, patientData) => ({
  type:appTypes.UPDATE_PATIENT_REQUEST,
  payload: { patientId, patientData },
});

export const updatePatientSuccess = (data) => ({
  type: appTypes.UPDATE_PATIENT_SUCCESS,
  payload: data,
});

export const updatePatientFailure = (error) => ({
  type:appTypes.UPDATE_PATIENT_FAILURE,
  payload: error,
});

//update area
export const updateAreaRequest = (areaId, areaData) => ({
  type:appTypes.UPDATE_AREA_REQUEST,
  payload: { areaId, areaData },
});

export const updateAreaSuccess = (data) => ({
  type: appTypes.UPDATE_AREA_SUCCESS,
  payload: data,
});

export const updateAreaFailure = (error) => ({
  type:appTypes.UPDATE_AREA_FAILURE,
  payload: error,
});

//update clinic
export const updateClinicRequest = (clinicId, clinicData) => ({
  type:appTypes.UPDATE_CLINIC_REQUEST,
  payload: { clinicId, clinicData },
});

export const updateClinicSuccess = (data) => ({
  type: appTypes.UPDATE_CLINIC_SUCCESS,
  payload: data,
});

export const updateClinicFailure = (error) => ({
  type:appTypes.UPDATE_CLINIC_FAILURE,
  payload: error,
});

//update staff
export const updateStaffRequest = (staffId, staffData) => ({
  type:appTypes.UPDATE_STAFF_REQUEST,
  payload: { staffId, staffData },
});

export const updateStaffSuccess = (data) => ({
  type: appTypes.UPDATE_STAFF_SUCCESS,
  payload: data,
});

export const updateStaffFailure = (error) => ({
  type:appTypes.UPDATE_STAFF_FAILURE,
  payload: error,
});

//update Tag
export const updateTagRequest = (tagId, tagData) => ({
  type:appTypes.UPDATE_TAG_REQUEST,
  payload: { tagId, tagData },
});

export const updateTagSuccess = (data) => ({
  type: appTypes.UPDATE_TAG_SUCCESS,
  payload: data,
});

export const updateTagFailure = (error) => ({
  type:appTypes.UPDATE_TAG_FAILURE,
  payload: error,
});

//update Billing
export const updateBillingRequest = (billingId, billingData) => ({
  type:appTypes.UPDATE_BILLING_REQUEST,
  payload: { billingId, billingData },
});

export const updateBillingSuccess = (data) => ({
  type: appTypes.UPDATE_BILLING_SUCCESS,
  payload: data,
});

export const updateBillingFailure = (error) => ({
  type:appTypes.UPDATE_BILLING_FAILURE,
  payload: error,
});

//update permission
export const updatePermissionRequest = (permissionId, permissionData) => ({
  type:appTypes.UPDATE_PERMISSION_REQUEST,
  payload: { permissionId, permissionData },
});

export const updatePermissionSuccess = (data) => ({
  type: appTypes.UPDATE_PERMISSION_SUCCESS,
  payload: data,
});

export const updatePermissionFailure = (error) => ({
  type:appTypes.UPDATE_PERMISSION_FAILURE,
  payload: error,
});

//update permission
export const updateMedicalRecordRequest = (medicalRecordId, medicalRecordData) => ({
  type:appTypes.UPDATE_MEDICALRECORD_REQUEST,
  payload: { medicalRecordId, medicalRecordData },
});

export const updateMedicalRecordSuccess = (data) => ({
  type: appTypes.UPDATE_MEDICALRECORD_SUCCESS,
  payload: data,
});

export const updateMedicalRecordFailure = (error) => ({
  type:appTypes.UPDATE_MEDICALRECORD_FAILURE,
  payload: error,
});

//update Template
export const updateTemplateRequest = (templateId, templateData) => ({
  type:appTypes.UPDATE_TEMPLATE_REQUEST,
  payload: { templateId, templateData },
});

export const updateTemplateSuccess = (data) => ({
  type: appTypes.UPDATE_TEMPLATE_SUCCESS,
  payload: data,
});

export const updateTemplateFailure = (error) => ({
  type:appTypes.UPDATE_TEMPLATE_FAILURE,
  payload: error,
});

//update notification
export const updateNotificationRequest = (notificationId, notificationData) => ({
  type:appTypes.UPDATE_NOTIFICATION_REQUEST,
  payload: { notificationId, notificationData },
});

export const updateNotificationSuccess = (data) => ({
  type: appTypes.UPDATE_NOTIFICATION_SUCCESS,
  payload: data,
});

export const updateNotificationFailure = (error) => ({
  type:appTypes.UPDATE_NOTIFICATION_FAILURE,
  payload: error,
});

//update notification
export const updateNotificationSettingRequest = (notificationSettingId, notificationSettingData) => ({
  type:appTypes.UPDATE_NOTIFICATIONSETTING_REQUEST,
  payload: { notificationSettingId, notificationSettingData },
});

export const updateNotificationSettingSuccess = (data) => ({
  type: appTypes.UPDATE_NOTIFICATIONSETTING_SUCCESS,
  payload: data,
});

export const updateNotificationSettingFailure = (error) => ({
  type:appTypes.UPDATE_NOTIFICATIONSETTING_FAILURE,
  payload: error,
});

//update Appointment
export const updateTokenAppointmentRequest = (appointmentId, appointmentData) => ({
  type:appTypes.UPDATE_TOKENAPPOINTMENT_REQUEST,
  payload: { appointmentId, appointmentData },
});

export const updateTokenAppointmentSuccess = (data) => ({
  type: appTypes.UPDATE_TOKENAPPOINTMENT_SUCCESS,
  payload: data,
});

export const updateTokenAppointmentFailure = (error) => ({
  type:appTypes.UPDATE_TOKENAPPOINTMENT_FAILURE,
  payload: error,
});


//update Message
export const updateMessageRequest = (messageId, messageData) => ({
  type:appTypes.UPDATE_MESSAGE_REQUEST,
  payload: { messageId, messageData },
});

export const updateMessageSuccess = (data) => ({
  type: appTypes.UPDATE_MESSAGE_SUCCESS,
  payload: data,
});

export const updateMessageFailure = (error) => ({
  type:appTypes.UPDATE_MESSAGE_FAILURE,
  payload: error,
});

//update Treatment
export const updateTreatmentRequest = (treatmentId, treatmentData) => ({
  type:appTypes.UPDATE_TREATMENT_REQUEST,
  payload: { treatmentId, treatmentData },
});

export const updateTreatmentSuccess = (data) => ({
  type: appTypes.UPDATE_TREATMENT_SUCCESS,
  payload: data,
});

export const updateTreatmentFailure = (error) => ({
  type:appTypes.UPDATE_TREATMENT_FAILURE,
  payload: error,
});

//update Followup
export const updateFollowupRequest = (followupId, followupData) => ({
  type:appTypes.UPDATE_FOLLOWUP_REQUEST,
  payload: { followupId, followupData },
});

export const updateFollowupSuccess = (data) => ({
  type: appTypes.UPDATE_FOLLOWUP_SUCCESS,
  payload: data,
});

export const updateFollowupFailure = (error) => ({
  type:appTypes.UPDATE_FOLLOWUP_FAILURE,
  payload: error,
});

//update Followup
export const updatePatientInfoRequest = (patientinfoId, patientinfoData) => ({
  type:appTypes.UPDATE_PATIENTINFO_REQUEST,
  payload: { patientinfoId, patientinfoData },
});

export const updatePatientInfoSuccess = (data) => ({
  type: appTypes.UPDATE_PATIENTINFO_SUCCESS,
  payload: data,
});

export const updatePatientInfoFailure = (error) => ({
  type:appTypes.UPDATE_PATIENTINFO_FAILURE,
  payload: error,
});




export const updateTaskStatusRequest = (taskId, status) => ({
  type: appTypes.UPDATE_TASK_STATUS_REQUEST,
  payload: { taskId, status },
});

export const updateTaskStatusSuccess = (task) => ({
  type: appTypes.UPDATE_TASK_STATUS_SUCCESS,
  payload: task,
});

export const updateTaskStatusFailure = (error) => ({
  type: appTypes.UPDATE_TASK_STATUS_FAILURE,
  payload: error,
});